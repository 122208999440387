<div class="tracking-box" [class.reversed]="sidesPeriod[period]">
  <div class="roster home" *ngIf="!sidesPeriod[period]">
    <div class="left-box">
      <div
        class="box backward"
        dragula="DRAGULA_1"
        [(dragulaModel)]="dragula_1"
        (dragulaModelChange)="dragula_1 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('home', 'backward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
      <div
        class="box goalkeeper"
        dragula="DRAGULA_2"
        [(dragulaModel)]="dragula_2"
        (dragulaModelChange)="dragula_2 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('home', 'goalkeeper')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div
        class="box forward"
        dragula="DRAGULA_3"
        [(dragulaModel)]="dragula_3"
        (dragulaModelChange)="dragula_3 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('home', 'forward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>

      <div class="new-record" *ngIf="page_type == 'supervize'">
        <button class="new-record-btn" (click)="toggleNewEvent()">
          Nový záznam
        </button>
      </div>
    </div>
  </div>
  <div class="roster away" *ngIf="sidesPeriod[period]">
    <div class="left-box">
      <div
        class="box forward"
        dragula="DRAGULA_11"
        [(dragulaModel)]="dragula_11"
        (dragulaModelChange)="dragula_11 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('away', 'forward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
      <!--
  <div class="cameras" *ngIf="page_type=='supervize'">
    <button class="camera-btn" [class.active]="enabled_camera==1" (click)="enabled_camera=1">Kamera 1</button>
    <button class="camera-btn" [class.active]="enabled_camera==2" (click)="enabled_camera=2">Kamera 2</button>
    <button class="camera-btn" [class.active]="enabled_camera==3" (click)="enabled_camera=3">Kamera 3</button>
  </div>
  -->
    </div>
    <div class="right-box">
      <div
        class="box backward"
        dragula="DRAGULA_21"
        [(dragulaModel)]="dragula_21"
        (dragulaModelChange)="dragula_21 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('away', 'backward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
      <div
        class="box goalkeeper"
        dragula="DRAGULA_31"
        [(dragulaModel)]="dragula_31"
        (dragulaModelChange)="dragula_31 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('away', 'goalkeeper')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
    </div>
  </div>
  <video-player
    [show_faceOff]="show_faceOff"
    [show_shot]="show_shot"
    [show_zoneEntry]="show_zoneEntry"
    [show_hit]="show_hit"
    [show_dumpIn]="show_dumpIn"
    [show_zoneExit]="show_zoneExit"
    [show_dumpOut]="show_dumpOut"
    [show_offensiveZoneLoss]="show_offensiveZoneLoss"
    [show_penalty]="show_penalty"
    [page_type]="page_type"
    [game]="game"
    [enabled_camera]="enabled_camera"
    [videoId]="videoId"
    [video]="video"
    [forceVideoLoad]="forceVideoLoad"
    [shotComponent]="shotComponent"
    [zoneEntryComponent]="zoneEntryComponent"
    [dumpInComponent]="dumpInComponent"
    [offensiveZoneLossComponent]="offensiveZoneLossComponent"
    [hitComponent]="hitComponent"
    [penaltyComponent]="penaltyComponent"
    [zoneExitComponent]="zoneExitComponent"
    [dumpOutComponent]="dumpOutComponent"
    [sidesPeriod]="sidesPeriod"
    [period]="period"
    [test_x]="test_x"
    [test_y]="test_y"
    (onCoordinatesChange)="coordinatesChange($event)"
  ></video-player>
  <div class="roster away" *ngIf="!sidesPeriod[period]">
    <div class="left-box">
      <div
        class="box forward"
        dragula="DRAGULA_11"
        [(dragulaModel)]="dragula_11"
        (dragulaModelChange)="dragula_11 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('away', 'forward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
      <!--
  <div class="cameras" *ngIf="page_type=='supervize'">
    <button class="camera-btn" [class.active]="enabled_camera==1" (click)="enabled_camera=1">Kamera 1</button>
    <button class="camera-btn" [class.active]="enabled_camera==2" (click)="enabled_camera=2">Kamera 2</button>
    <button class="camera-btn" [class.active]="enabled_camera==3" (click)="enabled_camera=3">Kamera 3</button>
  </div>
  -->
    </div>
    <div class="right-box">
      <div
        class="box backward"
        dragula="DRAGULA_21"
        [(dragulaModel)]="dragula_21"
        (dragulaModelChange)="dragula_21 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('away', 'backward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
      <div
        class="box goalkeeper"
        dragula="DRAGULA_31"
        [(dragulaModel)]="dragula_31"
        (dragulaModelChange)="dragula_31 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('away', 'goalkeeper')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="roster home" *ngIf="sidesPeriod[period]">
    <div class="left-box">
      <div
        class="box backward"
        dragula="DRAGULA_1"
        [(dragulaModel)]="dragula_11"
        (dragulaModelChange)="dragula_11 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('home', 'backward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
      <div
        class="box goalkeeper"
        dragula="DRAGULA_2"
        [(dragulaModel)]="dragula_22"
        (dragulaModelChange)="dragula_22 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('home', 'goalkeeper')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div
        class="box forward"
        dragula="DRAGULA_3"
        [(dragulaModel)]="dragula_33"
        (dragulaModelChange)="dragula_33 = $event"
      >
        <div
          class="player"
          *ngFor="let player of getRoster('home', 'forward')"
          (click)="selectPlayer(player.id)"
          [class.green]="checkPlayerOnIce(player.id)"
        >
          <div class="number">{{ player.jersey }}</div>
          <div class="name">{{ player.surname }}</div>
          <div class="post">{{ formatStick(player) }}</div>
        </div>
      </div>

      <div class="new-record" *ngIf="page_type === 'supervize'">
        <button class="new-record-btn" (click)="toggleNewEvent()">
          Nový záznam
        </button>
      </div>
    </div>
  </div>
</div>
