import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hokejPlayerErrorsSectionTranslate',
})
export class HokejPlayerErrorsSectionTranslatePipe implements PipeTransform {
  /**
   * Translates the section of the ExtraPlayer Error page into the normal readable string.
   * @param section string represented code to be translated.
   * @returns Translated section into the Czech language.
   */
  transform(section: string): string {
    const sectionArray: { [key: string]: string } = {
      player: 'Chybně označený hráč u události',
      time: 'Špatný čas události',
      other: 'Jiná chyba',
      resolved: 'Vyřešeno',
    };

    const foundedSection = sectionArray[section];
    return foundedSection ?? null;
  }
}
