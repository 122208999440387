<loading *ngIf="loading"></loading>

<div class="nav">
  <div class="nav-left">
    <ng-container
      *ngIf="
        isActive('edit-overview') ||
        isActive('error-overview') ||
        isActive('select') ||
        isActive('hokejplayer-errors')
      "
    >
      <a
        routerLink="/select"
        class="nav-item ico game"
        [class.active]="isActive('select')"
        >Výběr zápasu</a
      >
      <a
        routerLink="/error-overview"
        class="nav-item ico error"
        [class.active]="isActive('error-overview')"
        >Chyby a upozornění</a
      >
      <a
        routerLink="/edit-overview"
        class="nav-item ico overview"
        [class.active]="isActive('edit-overview')"
        >Přehled úprav</a
      >
      <a
        routerLink="/hokejplayer-errors"
        class="nav-item ico hokej-player"
        [class.active]="isActive('hokejplayer-errors')"
      >
        Chyby hokej.PLAYER
        <span *ngIf="hokejPlayerErrors?.length">{{
          hokejPlayerErrors.length
        }}</span>
      </a>
    </ng-container>

    <ng-container *ngIf="isActive('tracking')">
      <a routerLink="/select" class="nav-item ico tracking">Výběr zápasu</a>
      <div class="nav-item tracking active">Tracking</div>
    </ng-container>

    <ng-container *ngIf="isActive('supervize')">
      <a routerLink="/select" class="nav-item ico game">Výběr zápasu</a>
      <div class="nav-item tracking active">Supervize</div>
    </ng-container>

    <ng-container *ngIf="isActive('timeline')">
      <a routerLink="/select" class="nav-item ico game">Výběr zápasu</a>
      <div class="nav-item tracking">Supervize</div>
    </ng-container>
  </div>
  <div class="nav-right">
    <div class="nav-item ico user">{{ username }}</div>
    <a (click)="logout()" class="nav-item ico logout">Odhlásit se</a>
  </div>
</div>
