import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AppComponent } from '../../app.component';
import * as TrackingUtils from '../../utils/tracking-utils';
import * as TimeUtils from '../../utils/time-utils';
import * as data from '../../../lang.json';
import { IVideo } from '../../interfaces';
import {
  DumpInComponent,
  DumpOutComponent,
  FaceOffComponent,
  HitComponent,
  OffensiveZoneLossComponent,
  PenaltyComponent,
  SelectSideDialogComponent,
  ShootoutComponent,
  ShotComponent,
  ZoneEntryComponent,
  ZoneExitComponent,
} from '../../components';
import {
  DefaultService,
  DumpInService,
  DumpOutService,
  EventFlowService,
  FaceOffService,
  HitService,
  OffensiveZoneLossService,
  PassService,
  PuckWonService,
  ShotService,
  TimelineService,
  UtilService,
  ZoneEntryService,
  ZoneExitService,
} from '../../services';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  providers: [
    DefaultService,
    EventFlowService,
    FaceOffService,
    DumpInService,
    DumpOutService,
    HitService,
    OffensiveZoneLossService,
    ShotService,
    ZoneEntryService,
    ZoneExitService,
    TimelineService,
    UtilService,
    PassService,
    PuckWonService,
  ],
  host: {
    '(document:keypress)': 'handleKeyboardEvent($event)',
  },
})
export class TrackingComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private defaultService: DefaultService,
    private timelineService: TimelineService,
    private dialog: MatDialog
  ) {}

  public changeSidesLock = true;
  public sidesPeriod = {
    // to check reversed sides
    1: false,
    2: true,
    3: false,
    4: true,
    5: false,
    6: true,
  };

  filterErrorTypes = 'noErrors';
  id: number;
  openEvent: string;
  eventTime: number;

  show_faceOff = false;
  show_shot = false;
  show_zoneEntry = false;
  show_hit = false;
  show_dumpIn = false;
  show_zoneExit = false;
  show_dumpOut = false;
  show_offensiveZoneLoss = false;
  show_shootout = false;
  show_penalty = false;

  overtimeLength = 0;
  page_type = 'tracking';

  disabledWatch = false;

  period = 1;
  minute = 20;
  second = 0;

  game: any = [];
  eventflow: any = [];
  render_eventflow: any = [];
  filterEventFlow: boolean = false;

  filter_supervize = false;
  filter_period: number = 1;
  filter_team = 'all';
  filter_post = 'all';
  filter_events = 'all';

  enabled_camera = 1;
  toggle_adding_nav = false;

  event_list: any = [];
  // event_list: any = ['penalty'];

  roster_home: any = [];
  roster_away: any = [];

  show_coordinates = false;
  clickChangedSides = false;

  editData: any = {};

  loaded = false;
  loading = true;
  eventflow_loading = false;
  reload: any;

  casomira: number;

  videoId = '';
  video: IVideo;
  forceVideoLoad: boolean = false;

  test_x: number;
  test_y: number;

  activeFormation: any = [];

  enter_time = 0;
  timer: any;

  title = '';
  langData: any;
  invalidCasomira: boolean;
  passIdForDeletion: number;
  puckwonIdForDeletion: number;
  show_topMoment: boolean = false;
  show_penaltyShootout: boolean = false;
  requireEventFlow: boolean = false;
  @ViewChild(ShotComponent) shotComponent: ShotComponent;
  @ViewChild(ZoneEntryComponent) zoneEntryComponent: ZoneEntryComponent;
  @ViewChild(DumpInComponent) dumpInComponent: DumpInComponent;
  @ViewChild(OffensiveZoneLossComponent)
  offensiveZoneLossComponent: OffensiveZoneLossComponent;
  @ViewChild(HitComponent) hitComponent: HitComponent;
  @ViewChild(PenaltyComponent) penaltyComponent: PenaltyComponent;
  @ViewChild(ZoneExitComponent) zoneExitComponent: ZoneExitComponent;
  @ViewChild(FaceOffComponent) faceOffComponent: FaceOffComponent;
  @ViewChild(DumpOutComponent) dumpOutComponent: DumpOutComponent;
  @ViewChild(ShootoutComponent) shootoutComponent: ShootoutComponent;
  @ViewChild(AppComponent) appComponent: AppComponent;
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.disabledWatch = true;
    this.defaultService.removeWatch(this.id).subscribe(
      (data: any) => {
        this.toastr.success(
          'Sběr dat byl ukončen a zápas uzamčen.',
          'Výborně!',
          {
            progressBar: true,
          }
        );
      },
      (error) => {
        this.toastr.error('Během zamykání zápasu došlo k chybě.', 'Chyba!', {
          progressBar: true,
        });
      }
    );

    return false;
    // I have used return false but you can your other functions or any query or condition
  }

  ngOnInit(): void {
    this.langData = (data as any).default;
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.openEvent = this.route.snapshot.paramMap.get('openEvent');
    this.eventTime = Number(this.route.snapshot.paramMap.get('eventTime'));
    console.warn('ININITNG');
    console.log(this.id, this.openEvent, this.eventTime);
    if (this.router.url.includes('supervize')) {
      this.page_type = 'supervize';
      this.event_list = [
        'faceOff',
        'shot',
        'saved',
        'missed',
        'goal',
        'deflected',
        'post',
        'zoneEntry',
        'dumpIn',
        'zoneExit',
        'dumpOut',
        'shootout',
        'penalty',
        'topMoment',
        'penaltyShootout',
      ];
    } else {
      this.event_list = [
        'faceOff',
        'shot',
        'saved',
        'missed',
        'goal',
        'deflected',
        'post',
        'zoneEntry',
        'dumpIn',
        'zoneExit',
        'dumpOut',
        'shootout',
        'penalty',
        'topMoment',
        'penaltyShootout',
      ];
    }

    this.loadGame();

    this.relogCheck();

    this.timer = setInterval(() => this.relogCheck(), 10000);
  }

  loadGame() {
    this.loaded = false;
    this.defaultService.getMatch(this.id).subscribe(
      (data: any) => {
        this.game = data;
        this.openSelectSideDialog();
        this.videoId = data.videoId;

        this.timelineService.getVideoTime(this.id, 1).subscribe(
          (data: any) => {
            this.video = {
              matchId: this.id.toString(),
              type: 'full',
              text: '',
              seekTime: data.videoTime,
              videoId: this.videoId,
            };
          },
          (error) => {
            console.error(
              'Error while getting video time, playing video from the real start.\nError:',
              error
            );
            this.video = {
              matchId: this.id.toString(),
              type: 'full',
              text: '',
              seekTime: 0,
              videoId: this.videoId,
            };
          }
        );

        this.fillRosters(data);
        this.requireEventFlow = true;

        this.loadPlayersOnIce(true);

        this.overtimeLength = data.gameData.game.overtimeLength;
        localStorage.setItem(
          'overtimeLength',
          data.gameData.game.overtimeLength
        );
        this.loaded = true;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        if (error.status === 401) this.defaultService.logout();
        else if (error.status === 404) this.disabledWatch = true;
      }
    );
  }

  private openSelectSideDialog() {
    if (this.game) {
      const dialogRef = this.dialog.open(SelectSideDialogComponent, {
        width: '460px',
        disableClose: true,
        data: this.game,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) return;
        Object.keys(this.sidesPeriod).forEach(
          (el) => (this.sidesPeriod[el] = !this.sidesPeriod[el])
        );
      });
    }
  }

  reloadVideo(second: number) {
    this.forceVideoLoad = true;
    this.openVideoTime(second);
  }

  openVideoTime(second: number) {
    this.loading = false;

    this.timelineService
      .getVideoTime(this.id, second === 0 ? 1 : second)
      .subscribe(
        (data: any) => {
          this.video = {
            matchId: this.id.toString(),
            type: 'full',
            text: '',
            seekTime:
              this.page_type === 'supervize'
                ? data.videoTime - 5
                : data.videoTime,
            videoId: this.videoId,
          };
          setTimeout(() => (this.forceVideoLoad = false), 1000);
        },
        (error) => console.error('Open video time error:', error),
        () => (this.loading = false)
      );
  }

  openVideoTrueTime(second: number) {
    this.loading = true;
    this.video = {
      matchId: this.id.toString(),
      type: 'full',
      text: '',
      seekTime: second === 0 ? 1 : second,
      videoId: this.videoId,
    };

    this.loading = false;
  }

  onFilterEventFlow($event) {
    this.render_eventflow = $event;
    this.loadPlayersOnIce(false);
  }

  handleEventFlowChange($event) {
    this.eventflow = $event;
    if (
      this.eventflow.length > 0 &&
      this.openEvent &&
      this.eventTime !== undefined &&
      this.eventTime !== null
    ) {
      const event = this.eventflow.find(
        (event) =>
          event.event === this.openEvent && event.time === this.eventTime
      );
      if (event) {
        this.editEvent(event);
        this.openEvent = undefined;
        this.eventTime = undefined;
      }
    }
    this.loadPlayersOnIce(false);
  }

  stringy(ddd: any) {
    return '';
  }

  saveType(data: any) {
    const data_list = [];
    let selected_index = 0;
    if (data.save_type === 'save_next') {
      if (
        this.filter_events !== 'segment1' &&
        this.filter_events !== 'segment2'
      ) {
        // faceOff
        if (data.type === 'faceOff') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.faceOffId === data.id) selected_index = index;
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined)
              this.editEvent(this.render_eventflow[selected_index - 1]);
          }, 400);
        }

        // dumpIn
        if (data.type === 'dumpIn') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.dumpInId === data.id) selected_index = index;
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined)
              this.editEvent(this.render_eventflow[selected_index - 1]);
          }, 400);
        }

        // dumpOut
        if (data.type === 'dumpOut') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.dumpOutId === data.id) {
              selected_index = index;
            }
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined)
              this.editEvent(this.render_eventflow[selected_index - 1]);
          }, 400);
        }

        // hit
        if (data.type === 'hit') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.hitId === data.id) selected_index = index;
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined)
              this.editEvent(this.render_eventflow[selected_index - 1]);
          }, 400);
        }

        // offensiveZoneLoss
        if (data.type === 'offensiveZoneLoss') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.offensiveZoneLossId === data.id) selected_index = index;
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined)
              this.editEvent(this.render_eventflow[selected_index - 1]);
          }, 400);
        }

        // penalty
        if (data.type === 'penalty') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.penaltyId === data.id) selected_index = index;
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined)
              this.editEvent(this.render_eventflow[selected_index - 1]);
          }, 400);
        }

        // shootout
        console.log('data-type', data);
        if (data.type === 'shootout') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          // data_list.sort((a, b) => {
          //   return a.eventTime - b.eventTime;
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.shootoutId === data.id) {
              selected_index = index;
            }
          });
          console.clear();
          console.log('Data List', data_list);
          console.log('selected index', selected_index);
          console.log('data-type', data);
          console.log('__________');

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined) {
              this.editEvent(this.render_eventflow[selected_index - 1]);
            } else {
              // alert("konec seznamu")
            }
          }, 400);
        }

        if (data.type === 'shot') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.shotId === data.id) {
              selected_index = index;
            }
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined) {
              this.editEvent(this.render_eventflow[selected_index - 1]);
            } else {
              // alert("konec seznamu")
            }
          }, 400);
        }

        // zoneEntry
        if (data.type === 'zoneEntry') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.zoneEntryId === data.id) {
              selected_index = index;
            }
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined) {
              this.editEvent(this.render_eventflow[selected_index - 1]);
            } else {
              // alert("konec seznamu")
            }
          }, 400);
        }

        // zoneEntry
        if (data.type === 'zoneExit') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.zoneExitId === data.id) {
              selected_index = index;
            }
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined) {
              this.editEvent(this.render_eventflow[selected_index - 1]);
            } else {
              // alert("konec seznamu")
            }
          }, 400);
        }

        if (data.type === 'topMoment') {
          // this.render_eventflow.forEach((item) => {
          //   if (item.event === data.type) {
          //     data_list.push(item);
          //   }
          // });
          this.render_eventflow.forEach((item, index) => {
            if (item.topMomentId === data.id) {
              selected_index = index;
            }
          });

          setTimeout(() => {
            if (this.render_eventflow[selected_index - 1] !== undefined) {
              this.editEvent(this.render_eventflow[selected_index - 1]);
            } else {
              // alert("konec seznamu")
            }
          }, 400);
        }
      }

      if (this.filter_events === 'segment1') {
        this.render_eventflow.forEach((item) => {
          data_list.push(item);
        });
        data_list.forEach((item, index) => {
          if (item.shotId === data.id || item.faceOffId === data.id) {
            selected_index = index;
          }
        });

        setTimeout(() => {
          if (data_list[selected_index - 1] !== undefined) {
            this.editEvent(data_list[selected_index - 1]);
          } else {
            // alert("konec seznamu")
          }
        }, 400);
      }

      if (this.filter_events === 'segment2') {
        this.render_eventflow.forEach((item) => {
          data_list.push(item);
        });
        data_list.forEach((item, index) => {
          if (
            item.zoneExitId === data.id ||
            item.zoneEntryId === data.id ||
            item.dumpInId === data.id ||
            item.dumpOutId === data.id
          ) {
            selected_index = index;
          }
        });

        setTimeout(() => {
          if (data_list[selected_index - 1] !== undefined) {
            this.editEvent(data_list[selected_index - 1]);
          } else {
            // alert("konec seznamu")
          }
        }, 400);
      }
    }
  }

  onCoordinatesChange($event) {
    this.test_x = $event.test_x;
    this.test_y = $event.test_y;
  }

  relogCheck2() {
    // this.appComponent.relogCheck();
  }

  loadPlayersOnIce(open_video: boolean) {
    let time = this.minute * 60 + this.second;
    if (this.period === 1) time = (time - 1200) * -1;
    else if (this.period === 2) time = (time - 1200) * -1 + 1200;
    else if (this.period === 3) time = (time - 2400) * -1 + 1200;
    else if (this.period === 4) time = (time - 3600) * -1 + this.overtimeLength;
    else
      time =
        (time - (3600 + this.overtimeLength * (this.period - 3))) * -1 +
        this.overtimeLength;

    console.log('Time!', time);
    this.activeFormation = [];
    if (time >= 0) {
      this.defaultService.getPlayersOnIce(this.id, time).subscribe(
        (data: any) => {
          this.activeFormation = data;
        },
        (error) => {}
      );
    }

    if (time === 2400) {
      time = time - 1;
    }

    if (open_video) {
      this.openVideoTime(time);
    }

    // kotva
  }

  fillRosters(data: any) {
    data.roster.home.forEach((player) => {
      player.team_shortcut = data.gameData.homeTeam.shortcut;
      player.team_id = data.gameData.homeTeam.id;
      this.roster_home.push(player);
    });

    data.roster.away.forEach((player) => {
      player.team_shortcut = data.gameData.awayTeam.shortcut;
      player.team_id = data.gameData.awayTeam.id;
      this.roster_away.push(player);
    });
  }

  toggleCanvas(type: string, edit: boolean) {
    console.warn('Toggle canvas called!');
    console.log('Type:', type);
    console.log('ZoneEntry before:', this.show_zoneEntry);
    // window.scrollTo(0, document.body.scrollHeight);
    this.close(undefined, false);
    if (edit === false) this.editData = {};

    if (type === 'faceOff') this.show_faceOff = !this.show_faceOff;
    else if (type === 'shot') this.show_shot = !this.show_shot;
    else if (type === 'zoneEntry') this.show_zoneEntry = !this.show_zoneEntry;
    else if (type === 'dumpIn') this.show_dumpIn = !this.show_dumpIn;
    else if (type === 'zoneExit') this.show_zoneExit = !this.show_zoneExit;
    else if (type === 'dumpOut') this.show_dumpOut = !this.show_dumpOut;
    else if (type === 'offensiveZoneLoss')
      this.show_offensiveZoneLoss = !this.show_offensiveZoneLoss;
    else if (type === 'hit') this.show_hit = !this.show_hit;
    else if (type === 'shootout') {
      if (this.show_shootout) {
        this.show_shootout = false;
      } else {
        if (edit) {
          this.show_shootout = true;
        } else {
          this.toastr.info('Nový nájezd není možné vytvořit.', 'Info', {
            progressBar: true,
          });
        }
      }
    } else if (type === 'penalty') {
      if (this.show_penalty) {
        this.show_penalty = false;
      } else {
        if (edit) {
          this.show_penalty = true;
        } else {
          this.toastr.info('Nové vyloučení není možné vytvořit.', 'Info', {
            progressBar: true,
          });
        }
      }
    } else if (type === 'topMoment') {
      this.show_topMoment = !this.show_topMoment;
    } else if (type === 'penaltyShootout') {
      this.show_penaltyShootout = !this.show_penaltyShootout;
    }
    console.log('ZoneEntry after:', this.show_zoneEntry);
  }

  close(after_zone_exit: string, runVideo: boolean = true) {
    this.show_faceOff = false;
    this.show_shot = false;
    this.show_zoneEntry = false;
    this.show_dumpIn = false;
    this.show_zoneExit = false;
    this.show_dumpOut = false;
    this.show_offensiveZoneLoss = false;
    this.show_hit = false;
    this.show_shootout = false;
    this.show_penalty = false;
    this.show_topMoment = false;
    this.show_penaltyShootout = false;

    if (runVideo) {
      this.video = {
        matchId: this.id.toString(),
        type: 'full',
        text: '',
        seekTime: 0,
        videoId: this.videoId,
      };
    }

    if (after_zone_exit === 'dumpIn') {
      this.toggleCanvas('dumpIn', true);
    } else if (after_zone_exit === 'zoneEntry') {
      this.toggleCanvas('zoneEntry', true);
    }

    // Firing the trigger for the hook to load the event flow again.
    this.requireEventFlow = true;
  }

  setPeriod(period: number) {
    this.period = period;
    this.loadPlayersOnIce(false);
  }

  toLog(toLog: any) {
    console.log('Logged', toLog);
  }

  editEvent(event: any) {
    this.editData = event;
    this.relogCheck2();
    this.toggle_adding_nav = true;

    if (event.event === 'faceOff') {
      if (event.time !== 3599) {
        this.setCasomiraByEvent(event.time + 1, true);
      } else {
        this.setCasomiraByEvent(event.time, true);
      }
      this.toggleCanvas('faceOff', true);
    } else if (event.event === 'shot') {
      this.setCasomiraByEvent(event.time, true);
      const shotId = event.shotId;
      const topMoments = this.eventflow.filter(
        (x) => x.event === 'topMoment' && x.shotId === shotId
      );
      event['topMoments'] = topMoments;
      if (event.shotTimeBackup?.toString().length > 0) {
        event.time = event.shotTimeBackup;
      }
      this.toggleCanvas('shot', true);
    } else if (event.event === 'zoneEntry') {
      this.setCasomiraByEvent(event.time, true);
      this.toggleCanvas('zoneEntry', true);
    } else if (event.event === 'dumpIn') {
      this.setCasomiraByEvent(event.time, true);
      this.toggleCanvas('dumpIn', true);
    } else if (event.event === 'zoneExit') {
      this.setCasomiraByEvent(event.time, true);
      this.toggleCanvas('zoneExit', true);
    } else if (event.event === 'dumpOut') {
      this.setCasomiraByEvent(event.time, true);
      this.toggleCanvas('dumpOut', true);
    } else if (event.event === 'offensiveZoneLoss') {
      this.setCasomiraByEvent(event.time, true);
      this.toggleCanvas('offensiveZoneLoss', true);
    } else if (event.event === 'hit') {
      this.setCasomiraByEvent(event.time, true);
      this.toggleCanvas('hit', true);
    } else if (event.event === 'shootout') {
      this.toggleCanvas('shootout', true);
    } else if (event.event === 'penalty') {
      this.toggleCanvas('penalty', true);
      this.setCasomiraByEvent(event.created, true);
    } else if (event.event === 'topMoment') {
      this.setCasomiraByEvent(event.time, true);
      // let shotId = event.shotId;
      // let topMoments =this.eventflow.filter( x=>  x.event === "topMoment"  &&  x.shotId === shotId );
      //event['topMoments'] = topMoments
      this.toggleCanvas('topMoment', true);
    } else if (event.event === 'penaltyShootout') {
      this.toggleCanvas('penaltyShootout', true);
    }
  }

  /* kotva 2 */
  setCasomiraByEvent(time: number, open_video: boolean) {
    const time2: any[] = TimeUtils.formatTime(
      TrackingUtils.getCasomira(time, this.overtimeLength)
    ).split(':');
    // casomira for plus minus whitegreen input
    this.minute = Number(time2[0]);
    this.second = Number(time2[1]);
    this.loadPlayersOnIce(false);

    if (this.page_type === 'supervize' && open_video) this.openVideoTime(time);
  }

  formatTime2(seconds: number) {
    return (
      (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds
    );
  }

  toggleFilterSupervize() {
    this.filter_supervize = !this.filter_supervize;
    this.filterEventFlow = true;
  }

  toast(toastData: any) {
    if (toastData.type === 'success') {
      this.toastr.success(toastData.text, toastData.message, {
        progressBar: true,
      });
    } else if (toastData.type === 'error') {
      this.toastr.error(toastData.text, toastData.message, {
        progressBar: true,
      });
    } else if (toastData.type === 'warning') {
      this.toastr.warning(toastData.text, toastData.message, {
        progressBar: true,
      });
    } else if (toastData.type === 'info') {
      this.toastr.info(toastData.text, toastData.message, {
        progressBar: true,
      });
    }
  }

  /* Kotva */
  changeCasomira(data: any) {
    const time = data.time;
    const period = data.period;

    const times = time.split(':');

    let time2 = Number(times[0]) * 60 + Number(times[1]);
    if (this.period < 4) {
      if (time2 > 1200) {
        this.toast({
          type: 'error',
          text: 'Chybná hodnota',
          message: this.langData.invalidCasomira,
        });
        this.invalidCasomira = true;
      } else {
        this.invalidCasomira = false;
        time2 = 1200 * period - time2;
        this.setCasomiraByEvent(time2, false);
      }
    } else {
      if (time2 > this.overtimeLength) {
        this.toast({
          type: 'error',
          text: 'Chybná hodnota',
          message:
            this.langData.invalidCasomiraOT +
            (this.overtimeLength > 300 ? ' 20:00' : ' 05:00'),
        });
        this.invalidCasomira = true;
      } else {
        this.invalidCasomira = false;
        time2 = 3600 + this.overtimeLength * (this.period - 4) - time2;
        this.setCasomiraByEvent(time2, false);
      }
    }
  }

  canDeactivate() {
    if (confirm('Přejete si opustit zápas a uzamknout ho?')) {
      this.defaultService.removeWatch(this.id).subscribe(
        (data: any) => {
          this.toastr.success(
            'Sběr dat byl ukončen a zápas uzamčen.',
            'Výborně!',
            {
              progressBar: true,
            }
          );
          /* clearInterval(this.reload); */
        },
        (error) => {
          this.toastr.error('Během zamykání zápasu došlo k chybě.', 'Chyba!', {
            progressBar: true,
          });
        }
      );

      return true;
    }
    return false;
  }

  sync() {
    this.defaultService.matchSynchronize(String(this.id)).subscribe(
      (data: any) => {
        this.toastr.success('Synchronizace proběhla úspěšně.', 'Výborně!', {
          progressBar: true,
        });
        this.close(undefined);
      },
      (error) => {
        this.close(undefined);
        this.toastr.error('Během synchronizace došlo k chybě.', 'Chyba!', {
          progressBar: true,
        });
      }
    );
  }

  detectEdit(edit: any) {
    if (this.page_type === 'tracking') return edit.tracking === null;
    else if (this.page_type === 'supervize') return edit.supervision === null;
    return true;
  }

  getInfoButtonClass(event: any): string {
    if (event.error?.corrected) return 'success-btn';
    if (event.error?.type === 'warning' && this.page_type === 'supervize')
      return 'warning-btn';
    if (event.error?.type === 'error' && this.page_type === 'supervize')
      return 'error-btn';
    return 'info-btn';
  }

  logout() {
    localStorage.setItem('access_token', '');
    localStorage.setItem('logged_user', '');
    this.router.navigate(['/login']);
  }

  goToSelect() {
    this.router.navigate(['/select']);
  }

  getCasomiraSeconds() {
    let cas = 0;
    if (this.period === 1) cas = (this.minute * 60 + this.second - 1200) * -1;
    else if (this.period === 2)
      cas = (this.minute * 60 + this.second - 2400) * -1;
    else if (this.period === 3)
      cas = (this.minute * 60 + this.second - 3600) * -1;
    else if (this.period === 4)
      cas = (this.minute * 60 + this.second - 3600 + this.overtimeLength) * -1;
    else
      cas =
        (this.minute * 60 +
          this.second -
          (3600 + this.overtimeLength * (this.period - 3))) *
        -1;

    return cas < 0 ? 0 : cas;
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    const key = event.keyCode;
    if (
      this.show_faceOff === false &&
      this.show_shot === false &&
      this.show_zoneEntry === false &&
      this.show_hit === false &&
      this.show_dumpIn === false &&
      this.show_zoneExit === false &&
      this.show_dumpOut === false &&
      this.show_offensiveZoneLoss === false &&
      this.show_shootout === false &&
      this.show_penalty === false &&
      this.show_topMoment === false
    ) {
      if (key === 66 || key === 98) this.toggleCanvas('faceOff', false);
      else if (key === 115 || key === 83) this.toggleCanvas('shot', false);
      else if (key === 100 || key === 68) this.toggleCanvas('zoneEntry', false);
      else if (key === 110 || key === 78) this.toggleCanvas('dumpIn', false);
      else if (key === 122 || key === 90) this.toggleCanvas('zoneExit', false);
      else if (key === 118 || key === 86) this.toggleCanvas('dumpOut', false);
      else if (key === 117 || key === 85)
        this.toggleCanvas('offensiveZoneLoss', false);
      else if (key === 104 || key === 72) this.toggleCanvas('hit', false);
      else if (key === 116 || key === 84) this.toggleCanvas('shootout', false);
      else if (key === 112 || key === 80) this.toggleCanvas('penalty', false);
      else if (key === 109 || key === 77) this.toggleCanvas('topMoment', false);
    }
  }

  relogCheck() {
    // this.enter_time = this.enter_time + 1;
    /* this.enter_time === 240 */
    if (false) {
      clearInterval(this.timer);
      this.disabledWatch = true;
      this.defaultService.removeWatch(this.id).subscribe(
        (data: any) => {
          this.toastr.success(
            'Sběr dat byl ukončen a zápas uzamčen.',
            'Výborně!',
            {
              progressBar: true,
            }
          );
        },
        (error) => {
          this.toastr.error('Během zamykání zápasu došlo k chybě.', 'Chyba!', {
            progressBar: true,
          });
        }
      );
    }
  }
}
