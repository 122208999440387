<div class="clip-node" [class]="section" [ngClass]="{ odd: odd }">
  <div class="data">
    <div class="no-collapsable">
      <div class="section">
        {{ section | hokejPlayerErrorsSectionTranslate }}
      </div>
      <div class="count-of-errors">
        {{ wrongClipArray ? wrongClipArray.length : 0 }}
      </div>
      <div class="button">
        <toggle-button (onToggle)="collapseEdit()"></toggle-button>
      </div>
    </div>
    <div class="collapsable">
      <div class="sub-data sub-data--header">
        <div class="sub-data__report-date">Datum nahlášení</div>
        <div class="sub-data__game-date">Datum zápasu</div>
        <div class="sub-data__game">Zápas</div>
        <div class="sub-data__player">Hráč</div>
        <div class="sub-data__event">Událost</div>
        <div class="sub-data__time">Čas</div>
        <div class="buttons"></div>
      </div>
      <div class="sub-data" *ngFor="let clip of wrongClipArray; let i = index">
        <div class="clip-report-date">
          {{ clip.createdAt | date : "dd. MM. yyyy" }}
        </div>
        <div class="clip-game-date" *ngIf="clip.game">
          {{ clip.game.gameData.date | date : "dd. MM. yyyy" }}
        </div>
        <div class="clip-game" *ngIf="clip.game">
          <team-cell
            [awayShortcut]="clip.game.gameData.awayTeam.shortcut"
            [homeShortcut]="clip.game.gameData.homeTeam.shortcut"
          ></team-cell>
        </div>
        <div class="clip-reporter-jersey">
          {{ clip.reporter.jersey }} {{ clip.reporter.name[0] }}.
          {{ clip.reporter.surname }}
        </div>
        <div class="clip-event-type">
          {{ clip.event.type | eventTranslate }}
        </div>
        <div class="clip-event-time">
          {{ clip.event.time | translateSeconds }}
        </div>
        <div class="buttons">
          <button (click)="loadEditting(clip)">Editovat</button>
          <button class="primary" (click)="resolveClip(clip)">Vyřešit</button>
        </div>
      </div>
    </div>
  </div>
</div>
