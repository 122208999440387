<div
  class="tracking-selects"
  *ngIf="
    !show_faceOff &&
    !show_shot &&
    !show_zoneEntry &&
    !show_dumpIn &&
    !show_zoneExit &&
    !show_dumpOut &&
    !show_offensiveZoneLoss &&
    !show_hit &&
    !show_shootout &&
    !show_penalty &&
    !show_topMoment &&
    !show_penaltyShootout
  "
>
  <button
    *ngIf="page_type === 'supervize'"
    class="supervize-toggler"
    [class.active]="filter_supervize"
    (click)="toggleFilterSupervize()"
  >
    Supervize
  </button>

  <button
    *ngIf="page_type === 'tracking'"
    class="supervize-toggler"
    (click)="sync()"
  >
    Reload
  </button>

  <select
    [(ngModel)]="filter_period"
    (change)="onPeriodChange()"
    class="select-default-width"
  >
    <option value="1">1. třetina</option>
    <option value="2">2. třetina</option>
    <option value="3">3. třetina</option>
    <option
      *ngFor="let option of overtimeArray; let i = index"
      value="{{ i + 4 }}"
    >
      {{ option }}
    </option>
    <option *ngIf="showShootout" value="0">Nájezdy</option>
  </select>
  <select
    [(ngModel)]="filter_post"
    (change)="filterEventFlow()"
    class="select-default-width"
  >
    <option value="all">Všichni hráči</option>
    <option value="forwards">Útočníci</option>
    <option value="backwards">Obránci</option>
  </select>
  <select
    [(ngModel)]="filter_events"
    (change)="filterEventFlow()"
    class="select-default-width"
  >
    <option value="all">Všechny události</option>
    <option disabled>---------</option>
    <option value="segment1">1. Segment</option>
    <option value="segment2">2. Segment</option>
    <option disabled>---------</option>
    <option value="{{ event }}" *ngFor="let event of event_list">
      {{ event | eventTranslate }}
    </option>
  </select>
  <select
    [(ngModel)]="filter_team"
    (change)="filterEventFlow()"
    class="select-default-width"
  >
    <option value="all">Oba týmy</option>
    <option value="home">{{ game.gameData.homeTeam.name }}</option>
    <option value="away">{{ game.gameData.awayTeam.name }}</option>
  </select>
  <select
    [(ngModel)]="filter_error_types"
    (change)="filterEventFlow()"
    class="select-errors-width"
  >
    <option value="noErrors">{{ langData.error_select_default }}</option>
    <option value="all">{{ langData.allErrors }}</option>
    <option value="errors">{{ langData.errors }}</option>
    <option value="warnings">{{ langData.warnings }}</option>
  </select>

  <div class="more-game-buttons" *ngIf="page_type === 'supervize'">
    <button (click)="sync()">Synchronizovat</button>
  </div>
</div>
