import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IVideo } from '../../../interfaces';
import { DefaultService } from '../../../services';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnChanges {
  @Input() show_faceOff: boolean = false;
  @Input() show_shot: boolean = false;
  @Input() show_zoneEntry: boolean = false;
  @Input() show_dumpIn: boolean = false;
  @Input() show_offensiveZoneLoss: boolean = false;
  @Input() show_hit: boolean = false;
  @Input() show_penalty: boolean = false;
  @Input() show_zoneExit: boolean = false;
  @Input() show_dumpOut: boolean = false;

  @Input() sidesPeriod: any;
  @Input() period: number;

  @Input() page_type: string;
  @Input() game: any;
  @Input() enabled_camera: number;
  @Input() videoUrl: string;
  @Input() videoId: number;
  @Input() matchId: number;
  @Input() video: IVideo;
  @Input() forceVideoLoad: boolean;

  @Input() shotComponent;
  @Input() zoneEntryComponent;
  @Input() dumpInComponent;
  @Input() offensiveZoneLossComponent;
  @Input() hitComponent;
  @Input() penaltyComponent;
  @Input() zoneExitComponent;
  @Input() dumpOutComponent;

  @Input() test_x: number = null;
  @Input() test_y: number = null;

  @Output() onCoordinatesChange = new EventEmitter<{
    test_x: number;
    test_y: number;
  }>();

  x: number = null;
  y: number = null;
  show_coordinates: boolean = false;

  constructor(private defaultService: DefaultService) {}

  runVideo() {
    this.defaultService
      .getVideoPlayer({
        matchId: this.video.matchId,
        videoType: this.video.type,
        videoStartTime: this.video.videoTime,
        videoEndTime: this.video.videoEndTime,
        seekTime: this.video.seekTime,
        extraControls: true,
      })
      .subscribe((data) => {
        this.handleVideoPlayer(data);
      });
  }

  handleVideoPlayer(data) {
    const currentIframe = document.getElementById(
      'video-file-iframe'
    ) as HTMLIFrameElement;
    currentIframe.src = 'about:blank';

    setTimeout(() => {
      const iframeDocument = currentIframe.contentDocument;
      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(data || '');
        iframeDocument.close();
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.checkEmptyChange(changes.video) &&
      this.checkVideoChange(
        changes.video.currentValue,
        changes.video.previousValue
      )
    ) {
      this.video = changes.video.currentValue;
      this.runVideo();
    }
    if (this.checkEmptyChange(changes.game))
      this.game = changes.game.currentValue;
    if (this.checkEmptyChange(changes.period))
      this.period = changes.period.currentValue;

    // Recalculation of the coordinates.
    if (changes.test_x !== undefined && changes.test_x !== null)
      this.test_x = changes.test_x.currentValue;
    if (changes.test_y !== undefined && changes.test_y !== null)
      this.test_y = changes.test_y.currentValue;
    if (
      this.test_x !== undefined &&
      this.test_x !== null &&
      !Number.isNaN(this.test_x) &&
      this.test_y !== undefined &&
      this.test_y !== null &&
      !Number.isNaN(this.test_y)
    ) {
      this.recalculate();
      this.show_coordinates = true;
    }

    if (this.checkEmptyChange(changes.show_faceOff))
      this.show_faceOff = changes.show_faceOff.currentValue;
    if (this.checkEmptyChange(changes.show_shot))
      this.show_shot = changes.show_shot.currentValue;
    if (this.checkEmptyChange(changes.show_zoneEntry))
      this.show_zoneEntry = changes.show_zoneEntry.currentValue;
    if (this.checkEmptyChange(changes.show_dumpIn))
      this.show_dumpIn = changes.show_dumpIn.currentValue;
    if (this.checkEmptyChange(changes.show_offensiveZoneLoss))
      this.show_offensiveZoneLoss = changes.show_offensiveZoneLoss.currentValue;
    if (this.checkEmptyChange(changes.show_hit))
      this.show_hit = changes.show_hit.currentValue;
    if (this.checkEmptyChange(changes.show_penalty))
      this.show_penalty = changes.show_penalty.currentValue;
    if (this.checkEmptyChange(changes.show_zoneEntry))
      this.show_zoneExit = changes.show_zoneEntry.currentValue;
    if (this.checkEmptyChange(changes.show_dumpOut))
      this.show_dumpOut = changes.show_dumpOut.currentValue;
    if (this.checkEmptyChange(changes.ZoneEntryComponent))
      this.zoneEntryComponent = changes.zoneEntryComponent.currentValue;
  }

  checkEmptyChange = (value) => value?.currentValue != null;

  checkVideoChange(currentVideo: IVideo, previousVideo: IVideo) {
    if (!currentVideo || !previousVideo || this.forceVideoLoad) return true;
    const hasVideoChanged = Object.keys(currentVideo).reduce((acc, key) => {
      if (currentVideo[key] !== previousVideo[key]) {
        acc = true;
      }
      return acc;
    }, false);
    return hasVideoChanged;
  }

  onMapClick(event): void {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    this.show_coordinates = true;

    this.x = x;
    this.y = y;

    if (this.show_shot) {
      this.shotComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y'),
        true
      );
    } else if (this.show_zoneEntry) {
      this.zoneEntryComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y'),
        true
      );
    } else if (this.show_dumpIn) {
      this.dumpInComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y'),
        true
      );
    } else if (this.show_offensiveZoneLoss) {
      this.offensiveZoneLossComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y')
      );
    } else if (this.show_hit) {
      this.hitComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y')
      );
    } else if (this.show_penalty) {
      this.penaltyComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y'),
        true
      );
    } else if (this.show_zoneExit) {
      this.zoneExitComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y'),
        true
      );
    } else if (this.show_dumpOut) {
      this.dumpOutComponent.sendCoordinates(
        this.recountCoordinates(x, y, 'x'),
        this.recountCoordinates(x, y, 'y')
      );
    }

    this.test_x = this.recountCoordinates(x, y, 'x');
    this.test_y = this.recountCoordinates(x, y, 'y');
    this.onCoordinatesChange.emit({ test_x: this.test_x, test_y: this.test_y });
  }

  recountCoordinates(x: number, y: number, coordinate: string) {
    x = x - 380;
    y = y - 380 / 2;

    if (!this.sidesPeriod[this.period]) {
      x = this.remap(x, 380, -380, 100, -100);
      y = this.remap(y, 190, -190, 100, -100) * -1;
      if (
        this.show_dumpIn ||
        this.show_zoneEntry ||
        this.show_zoneExit ||
        this.show_dumpOut
      ) {
        if (x < 1) {
          x = -24;
          this.x = 286;
        } else {
          x = 24;
          this.x = 470;
        }
      }
      if (coordinate === 'x') return x;
      return y;
    } else {
      x = this.remap(x, 380, -380, 100, -100) * -1;
      if (
        this.show_dumpIn ||
        this.show_zoneEntry ||
        this.show_zoneExit ||
        this.show_dumpOut
      ) {
        if (x < 1) {
          x = -24;
          this.x = 470;
        } else {
          x = 24;
          this.x = 286;
        }
      }
      y = this.remap(y, 190, -190, 100, -100);
      if (coordinate === 'x') return x;
      return y;
    }
  }

  remap(
    value: number,
    in_min: number,
    in_max: number,
    out_min: number,
    out_max: number
  ) {
    return Math.ceil(
      ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
  }

  /**
   * Recalculates the obtained coordinates from some component.
   */
  recalculate(): void {
    let width = 760;
    let height = 380;
    let coorsX = (width / 200) * Math.abs(this.test_x);
    let coorsY = (height / 200) * Math.abs(this.test_y);

    this.x = this.calculateX(width, coorsX);
    this.y = this.calculateY(height, coorsY);

    this.test_x = this.recountCoordinates(this.x, this.y, 'x');
    this.test_y = this.recountCoordinates(this.x, this.y, 'y');
  }

  /**
   * Returns the coordinate of the x.
   */
  calculateX(width: number, coorsX: number): number {
    if (!this.sidesPeriod[this.period]) {
      if (this.test_x < 0) return width / 2 - coorsX;
      if (this.test_x > 0) return width / 2 + coorsX;
      return width / 2;
    }

    if (this.test_x > 0) return width / 2 - coorsX;
    if (this.test_x < 0) return width / 2 + coorsX;
    return width / 2;
  }

  /**
   * Returns the coordinate of the x.
   */
  calculateY(height: number, coorsY: number): number {
    if (!this.sidesPeriod[this.period]) {
      if (this.test_y > 0) return height / 2 - coorsY;
      if (this.test_y < 0) return height / 2 + coorsY;
      return height / 2;
    }

    if (this.test_y < 0) return height / 2 - coorsY;
    if (this.test_y > 0) return height / 2 + coorsY;
    return height / 2;
  }
}
