import { IEvent } from './../interfaces/match.interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventTransform',
})
export class EventTransformPipe implements PipeTransform {
  transform(value: IEvent[], type: string): string {
    let count: number = 0;
    if (value === undefined || value === null) return null;
    if (value.length > 0)
      count = value.filter((value) => value.error_type === type).length;
    let countString: string = '<span>' + count + '</span>';
    return type === 'error'
      ? this.returnError(count, countString)
      : this.returnNotification(count, countString);
  }
  returnError(count: number, countString: string): string {
    let iconString = count === 0 ? 'pass' : 'error';
    return (
      '<img src="/assets/image/icon-' +
      iconString +
      '-colored.svg" />' +
      countString
    );
  }
  returnNotification(count: number, countString: string): string {
    let iconString = count < 11 ? 'pass' : 'warning';
    return (
      '<img src="/assets/image/icon-' +
      iconString +
      '-colored.svg" />' +
      countString
    );
  }
}
