import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IVideo } from '../../../interfaces';

@Component({
  selector: 'tracking-box',
  templateUrl: './tracking-box.component.html',
  styleUrls: ['./tracking-box.component.scss'],
})
export class TrackingBoxComponent implements OnChanges {
  @Input() show_shootout: boolean = false;
  @Input() show_faceOff: boolean = false;
  @Input() show_shot: boolean = false;
  @Input() show_zoneEntry: boolean = false;
  @Input() show_dumpIn: boolean = false;
  @Input() show_offensiveZoneLoss: boolean = false;
  @Input() show_hit: boolean = false;
  @Input() show_penalty: boolean = false;
  @Input() show_zoneExit: boolean = false;
  @Input() show_dumpOut: boolean = false;

  @Input() sidesPeriod: any;
  @Input() period: number;

  @Input() activeFormation: any = [];
  @Input() page_type: string;
  @Input() game: any;
  @Input() enabled_camera: number;
  @Input() videoId: number;
  @Input() video: IVideo;
  @Input() forceVideoLoad: boolean;
  @Input() toggle_adding_nav: boolean;

  @Input() shootoutComponent;
  @Input() faceOffComponent;
  @Input() shotComponent;
  @Input() zoneEntryComponent;
  @Input() dumpInComponent;
  @Input() offensiveZoneLossComponent;
  @Input() hitComponent;
  @Input() penaltyComponent;
  @Input() zoneExitComponent;
  @Input() dumpOutComponent;

  dragula_1: any;
  dragula_2: any;
  dragula_3: any;
  dragula_11: any;
  dragula_21: any;
  dragula_31: any;
  dragula_22: any;
  dragula_33: any;

  @Input() test_x: number = null;
  @Input() test_y: number = null;

  @Output() onCoordinatesChange = new EventEmitter<{
    test_x: number;
    test_y: number;
  }>();
  @Output() onClose = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkEmptyChange(changes.activeFormation))
      this.activeFormation = changes.activeFormation.currentValue;
  }

  checkEmptyChange = (value) => value?.currentValue != null;

  selectPlayer(id: string) {
    if (this.show_shot) this.shotComponent.selectPlayerByClick(id);
    else if (this.show_faceOff) this.faceOffComponent.selectPlayerByClick(id);
    else if (this.show_dumpIn) this.dumpInComponent.selectPlayerByClick(id);
    else if (this.show_dumpOut) this.dumpOutComponent.selectPlayerByClick(id);
    else if (this.show_hit) this.hitComponent.selectPlayerByClick(id);
    else if (this.show_offensiveZoneLoss)
      this.offensiveZoneLossComponent.selectPlayerByClick(id);
    else if (this.show_shootout) this.shootoutComponent.selectPlayerByClick(id);
    else if (this.show_zoneEntry)
      this.zoneEntryComponent.selectPlayerByClick(id);
    else if (this.show_zoneExit) this.zoneExitComponent.selectPlayerByClick(id);
    else if (this.show_penalty) this.penaltyComponent.selectPlayerByClick(id);
  }

  checkPlayerOnIce(id: number) {
    return this.activeFormation.includes(id);
  }

  getRoster(type: string, post: string) {
    const roster = [];
    if (type === 'home') {
      this.game.roster.home.forEach((player) => {
        if (post === 'forward') {
          if (player.position === 'forward') {
            roster.push(player);
          }
        } else if (post === 'backward') {
          if (player.position === 'backward') {
            roster.push(player);
          }
        } else if (post === 'goalkeeper') {
          if (player.position === 'goalkeeper') {
            roster.push(player);
          }
        }
      });
    } else if (type === 'away') {
      this.game.roster.away.forEach((player) => {
        if (post === 'forward') {
          if (player.position === 'forward') {
            roster.push(player);
          }
        } else if (post === 'backward') {
          if (player.position === 'backward') {
            roster.push(player);
          }
        } else if (post === 'goalkeeper') {
          if (player.position === 'goalkeeper') {
            roster.push(player);
          }
        }
      });
    }

    roster.forEach((item) => {
      if (item.lineUp !== undefined && item.lineUp !== null)
        item.lajna = item.lineUp.line;
      else item.lajna = 1000;
    });

    roster.sort((p1, p2) => p1.lajna - p2.lajna);

    return roster;
  }

  formatStick(player: any) {
    if (player.position === 'goalkeeper') return 'G';
    if (player.stick === 'left') return 'L';
    if (player.stick === 'right') return 'P';
  }

  toggleNewEvent() {
    if (this.toggle_adding_nav) {
      this.toggle_adding_nav = false;
      this.onClose.emit();
    } else {
      this.toggle_adding_nav = true;
    }
  }

  coordinatesChange($event) {
    this.onCoordinatesChange.emit($event);
  }
}
