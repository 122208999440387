import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventTranslate',
})
export class EventTranslatePipe implements PipeTransform {
  /**
   * Translates event into Czech sence.
   * @param type Type of event.
   * @returns Type in Czech language.
   */
  transform(type: string): string {
    let eventArray: { [key: string]: string } = {
      faceOff: 'Vhazování',
      shot: 'Střela',
      saved: 'Střela - chycená',
      missed: 'Střela - mimo',
      goal: 'Střela - gól',
      deflected: 'Střela - zablokovaná',
      zoneEntry: 'Vstup do pásma',
      dumpIn: 'Nahození',
      zoneExit: 'Výstup z pásma',
      dumpOut: 'Vyhození',
      offensiveZoneLoss: 'Ztráta út. pásma',
      hit: 'Hit',
      shootout: 'Nájezd',
      penalty: 'Vyloučení',
      post: 'Střela - tyč',
      topMoment: 'TOP moment',
      penaltyShootout: 'Trestné střílení',
      pass: 'Přihrávka na střelu',
      puckWon: 'Zisk puku',
      shift: 'Střídání',
    };

    let foundedType = eventArray[type];
    if (foundedType !== undefined && foundedType !== null) return foundedType;

    console.warn('Typ', type, 'neexistuje.');
    return null;
  }
}
