import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'tracking-selects',
  templateUrl: './tracking-selects.component.html',
  styleUrls: ['./tracking-selects.component.scss'],
})
export class TrackingSelectsComponent implements OnInit {
  @Input() show_hit: boolean;
  @Input() show_faceOff: boolean;
  @Input() show_shot: boolean;
  @Input() show_zoneEntry: boolean;
  @Input() show_dumpIn: boolean;
  @Input() show_zoneExit: boolean;
  @Input() show_dumpOut: boolean;
  @Input() show_offensiveZoneLoss: boolean;
  @Input() show_shootout: boolean;
  @Input() show_penalty: boolean;
  @Input() show_penaltyShootout: boolean;
  @Input() show_topMoment: boolean;

  @Input() page_type: string;
  @Input() period: number;
  @Input() event_list: any[];
  @Input() game: any;
  @Input() langData: any;

  @Input() filter_supervize: string;
  @Input() filter_period: string;
  @Input() filter_post: string;
  @Input() filter_events: string;
  @Input() filter_team: string;
  @Input() filter_error_types: string;
  @Input() filter_event_flow: boolean;
  @Input() eventflow: any[];
  render_eventflow: any[];
  overtimeLength: number;
  overtimeArray: string[] = [];
  showShootout: boolean = false;

  @Output() onToggleFilterSupervize = new EventEmitter();
  @Output() onLoadEventFlow = new EventEmitter();
  @Output() onSetPeriod = new EventEmitter<number>();
  @Output() onLoadPlayersOnIce = new EventEmitter<boolean>();
  @Output() onSync = new EventEmitter();
  @Output() onSyncAndReload = new EventEmitter();
  @Output() onFilterEventFlow = new EventEmitter<any[]>();

  constructor() {}

  ngOnInit(): void {
    this.loadEventFlow();
  }

  /**
   * Since we want to hold the filtering of the eventflow here, we have to know
   * if the parrent component wants to filter the eventflow so we are passing the boolean
   * value and we are catching the change of it so we can filter the eventflow and send it back.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.eventflow?.currentValue !== undefined &&
      changes.eventflow?.currentValue !== null
    ) {
      this.eventflow = changes.eventflow.currentValue;
      this.overtimeLength = this.game.gameData.game.overtimeLength;
      this.fillNeededPeriods();
      this.checkForShootout();
      this.filterEventFlow();
    }
    if (
      changes.period?.currentValue !== undefined &&
      changes.period?.currentValue !== null
    ) {
      this.period = changes.period.currentValue;
      this.filter_period = this.period.toString();
      this.fillNeededPeriods();
      this.filterEventFlow();
    }
    if (
      changes.filter_event_flow === null ||
      changes.filter_event_flow === undefined ||
      !changes.filter_event_flow.currentValue
    )
      return;

    this.filter_event_flow = false;
    this.filterEventFlow();
  }

  toggleFilterSupervize(): void {
    this.onToggleFilterSupervize.emit();
  }

  loadEventFlow(): void {
    this.onLoadEventFlow.emit();
  }

  reloadEventFlow(): void {
    this.onSyncAndReload.emit();
  }

  fillNeededPeriods(): void {
    if (this.game.gameData.game.overtimeCount === 0) return;
    this.overtimeArray = ['prod.'];
    if (this.game.gameData.game.overtimeCount === 1) return;
    if (this.eventflow.length < 1) return;

    let longestTime = this.eventflow.sort()[this.eventflow.length - 1].time;
    let numberOfOT = Math.floor(
      (longestTime / 60 - 60) / (this.overtimeLength / 60)
    );
    for (let i = 0; i < numberOfOT; i++)
      this.overtimeArray.push('prod.' + (i + 2));
  }

  checkForShootout(): void {
    this.showShootout =
      this.eventflow.find((item) => item.event === 'shootout') !== undefined;
  }

  onPeriodChange(): void {
    this.period = +this.filter_period;
    this.onSetPeriod.emit(this.period);
    this.filterEventFlow();
  }

  filterEventFlow(): void {
    this.render_eventflow = [];
    // setting the type of the value from the select tag to be a number.
    let filterPeriod: number = +this.filter_period;

    //this.eventflow = this.eventflow.filter( x=> !(x.event === "topMoment" &&  x.shotId !== null) ) ;
    //if (filterPeriod > 0) this.onSetPeriod.emit(filterPeriod);

    let eventArray = ['segment1', 'segment2', ...this.event_list];
    this.eventflow = this.eventflow.filter((item) =>
      eventArray.includes(item.event)
    );

    if (filterPeriod === 1) {
      this.eventflow.forEach((item) => {
        if (item.event === 'penalty') {
          if (item.created > -1 && item.created < 1200) {
            this.render_eventflow.push(item);
          }
        } else {
          if (item.eventTime === null) return;
          if (item.eventTime > -1 && item.eventTime < 1200) {
            this.render_eventflow.push(item);
          }
        }
      });
    } else if (filterPeriod === 0) {
      this.eventflow.forEach((item) => {
        if (item.event === 'shootout') {
          item.eventTime = item.videoTime;
          this.render_eventflow.push(item);
        }
      });
      this.loadPlayersOnIce(false);
    } else {
      this.eventflow.forEach((item) => {
        if (filterPeriod < 4) {
          if (item.event === 'penalty') {
            if (
              item.created >= 1200 * (filterPeriod - 1) &&
              item.created < 1200 * filterPeriod
            ) {
              this.render_eventflow.push(item);
            }
          } else {
            if (
              item.eventTime >= 1200 * (filterPeriod - 1) &&
              item.eventTime < 1200 * filterPeriod
            ) {
              this.render_eventflow.push(item);
            }
          }
        } else {
          if (item.event === 'penalty') {
            if (
              item.created >= 3600 + this.overtimeLength * (filterPeriod - 4) &&
              item.created <
                3600 +
                  this.overtimeLength * (filterPeriod - 4) +
                  this.overtimeLength
            ) {
              this.render_eventflow.push(item);
            }
          } else {
            if (
              item.eventTime >=
                3600 + this.overtimeLength * (filterPeriod - 4) &&
              item.eventTime <
                3600 +
                  this.overtimeLength * (filterPeriod - 4) +
                  this.overtimeLength
            ) {
              this.render_eventflow.push(item);
            }
          }
        }
      });
      this.loadPlayersOnIce(false);
    }

    if (this.filter_post !== 'all')
      this.render_eventflow = this.render_eventflow.filter(
        (item) => item.filter_player_post + 's' === this.filter_post
      );

    if (this.filter_events === 'all') {
      this.render_eventflow = this.render_eventflow.filter((item) => item);
      // Filter anything
    } else if (this.filter_events === 'segment1') {
      this.render_eventflow = this.render_eventflow.filter(
        (item) =>
          item.event === 'faceOff' ||
          item.event === 'shot' ||
          item.event === 'penalty'
      );
    } else if (this.filter_events === 'segment2') {
      this.render_eventflow = this.render_eventflow.filter(
        (item) =>
          item.event === 'zoneExit' ||
          item.event === 'zoneEntry' ||
          item.event === 'dumpIn' ||
          item.event === 'dumpOut'
      );
    } else {
      this.render_eventflow = this.render_eventflow.filter(
        (item) =>
          this.filter_events === item.event ||
          this.filter_events === item.result
      );
    }

    if (this.filter_team === 'all') {
      this.render_eventflow = this.render_eventflow.filter(
        (item) => item.event !== 'topMoment' || item.shotId === null
      );
    } else {
      this.render_eventflow = this.render_eventflow.filter(
        (item) => this.filter_team === item.filter_player_team
      );
    }

    if (this.filter_supervize) {
      this.render_eventflow = this.render_eventflow.filter(
        (item) => item.supervision !== null
      );
    }

    if (this.filter_error_types !== 'noErrors') {
      this.render_eventflow = this.render_eventflow.filter((item) => {
        if (item.error !== null && item.error !== undefined) {
          if (this.filter_error_types === 'all') {
            if (['error', 'warning'].includes(item.error.type)) return item;
          } else if (this.filter_error_types === 'errors') {
            if ('error' === item.error.type) return item;
          } else if (this.filter_error_types === 'warnings') {
            if ('warning' === item.error.type) return item;
          }
        }
      });
    }

    if (filterPeriod === 0) {
      this.render_eventflow = this.render_eventflow.sort(
        (a, b) => a.eventTime - b.eventTime
      );
    } else {
      this.render_eventflow = this.render_eventflow.sort(
        (a, b) => b.eventTime - a.eventTime
      );
    }

    this.onFilterEventFlow.emit(this.render_eventflow);
  }

  loadPlayersOnIce(loadPlayers: boolean): void {
    this.onLoadPlayersOnIce.emit(loadPlayers);
  }

  sync(): void {
    this.onSync.emit();
  }
}
