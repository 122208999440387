import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { ShotService } from '../../../services/shot.service';
import { PuckWonService } from '../../../services/puckWon.service';
import { PassService } from '../../../services/pass.service';

import { MapComponent } from '../../common/map/map.component';
import { DefaultService } from '../../../services/default.service';
import * as TrackingUtils from 'src/app/utils/tracking-utils';
import * as TimeUtils from 'src/app/utils/time-utils';
import * as data from '../../../../lang.json';

interface IPlayer {
  id: string;
  name: string;
  team: string;
  active: boolean;
}

@Component({
  selector: 'shot-canvas',
  templateUrl: './shot.component.html',
  styleUrls: ['./shot.component.scss'],
  providers: [ShotService, PuckWonService, PassService, DefaultService],
})
export class ShotComponent implements OnInit {
  @Output() closeCanvas = new EventEmitter<any>();
  @Output() onCoordinatesChange = new EventEmitter<{
    test_x: number;
    test_y: number;
  }>();
  @ViewChild('scroller') private scroller: ElementRef;
  @Input() page_type: string;

  @Output() toast = new EventEmitter<any>();
  @Output() changeCasomira = new EventEmitter<any>();
  @Input() invalidCasomira: boolean;

  show_playground: boolean = false;
  editPage: boolean = false;

  shot_active: string = '';

  puckWon_list: any = [];

  //rosters
  @Input() roster_home: any = [];
  @Input() roster_away: any = [];
  roster_all: any = [];

  goalkeepers_all: any = [];
  goalkeepers_home: any = [];
  goalkeepers_away: any = [];

  private min = 0;
  private sec = 0;

  @Input() set minute(min) {
    this.min = min;
    this.setTime();
  }
  @Input() set second(sec) {
    this.sec = sec;
    this.setTime();
  }
  @Input() period: number;
  @Input() casomira: number;
  @Input() editData: any;
  @Input() reversed_sides: boolean = false;

  @Input() test_x: number;
  @Input() test_y: number;

  @Input() activeFormation: any = [];
  @Output() reloadVideo = new EventEmitter<any>();
  @Output() saveType = new EventEmitter<any>();

  shootingPlayer: IPlayer = { id: null, name: '', team: '', active: false };
  blockingPlayer: IPlayer = { id: null, name: '', team: '', active: false };
  goalkeeper: IPlayer = { id: null, name: '', team: '', active: false };
  puckWinPlayer: IPlayer = { id: null, name: '', team: '', active: false };
  puckPointsSharedPlayer: IPlayer = {
    id: null,
    name: '',
    team: '',
    active: false,
  };
  possesionPuckLoser: IPlayer = { id: null, name: '', team: '', active: false };
  passingPlayer: IPlayer = { id: null, name: '', team: '', active: false };

  players: IPlayer[] = [
    this.shootingPlayer,
    this.blockingPlayer,
    this.goalkeeper,
    this.puckWinPlayer,
    this.puckPointsSharedPlayer,
    this.possesionPuckLoser,
    this.passingPlayer,
  ];

  blockerId: string = '';

  matchId: number;

  lokace: string = '';
  lokace_active: boolean = false;
  lokace2: string = '';
  lokace_active2: boolean = false;
  lokace3: string = '';
  lokace_active3: boolean = false;

  result: string = '';

  oneTimer: boolean = false;
  penaltyShot: boolean = false;
  deflected: boolean = false;
  forecheck: boolean = false;
  quickAttack: boolean = false;
  oddManRush: boolean = false;
  completeToSlot: boolean = false;
  nonCompleteToSlot: boolean = false;
  blockedToSlot: boolean = false;

  rebound: boolean = false;
  screeningPlayer: boolean = false;
  blocked: boolean = false;

  show_coordinates: boolean = false;

  loading: boolean = false;

  shotId: number;

  overtimeLength: number = 0;

  time: string = '';
  puck_type: string = '';
  puck_time2: string = '';
  puck_time3: string = '';

  passes_list: any = [];
  prihravka_na_strelu: boolean = undefined;

  puckWon_exist: boolean = false;
  pass_exist: boolean = false;

  puckWonId: number;
  passId: number;

  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  contextmenu_attribute: string = '';
  showhelp: boolean = false;
  help_title: string = '';
  help_desc: string = '';
  active_supervize: string = 'shot';

  zoneExitId: number;
  goalieTouch: string = '';

  underPressure: boolean = false;
  participation: any = [];

  realDate_shot: string = '';
  realDate_puckWon: string = '';

  attacking_players: number = undefined;
  defending_players: number = undefined;
  show_tooltyp: boolean = false;
  show_saved_tooltyp: boolean = false;
  show_top_moment_tooltyp: boolean = false;
  top_moment_type: string = null;
  save_type: string = '';
  caught_save_type: string = null;
  goal = false;
  langData: any;

  @Input() homeShortcut: string = '';
  @Input() awayShortcut: string = '';

  supervize: any = {
    playerId: false,
    timeOnIce: false,
    goalkeeperId: false,
    time: false,
    result: false,
    coordinates: false,
    rebound: false,
    screeningPlayer: false,
    oneTimer: false,
    blocked: false,
    quickAttack: false,
    forecheck: false,
    oddManRush: false,
    gateZone: false,
  };

  supervize_zisk: any = {
    time: false,
    playerId: false,
    gainSharePlayerId: false,
    puckLostPlayerId: false,
    coordinates: false,
    shotId: false,
    zoneExitId: false,
    dumpOutId: false,
    realDate: false,
    type: false,
    goalieTouch: false,
    underPressure: false,
    participation: false,
  };

  supervize_pass: any = {
    time: false,
    playerId: false,
    blockerId: false,
    shotId: false,
    coordinates: false,
    quickAttack: false,
    forecheck: false,
    oddManRush: false,
    completeToSlot: false,
    nonCompleteToSlot: false,
    blockedToSlot: false,
  };

  obranne_pasmo_strilejiciho_tymu: boolean = false;

  @ViewChild(MapComponent) mapComponent: MapComponent;

  constructor(
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private shotService: ShotService,
    private puckWonService: PuckWonService,
    private passService: PassService,
    private defaultService: DefaultService
  ) {
    this.matchId = Number(this.route.snapshot.paramMap.get('id'));
    this.overtimeLength = Number(localStorage.getItem('overtimeLength'));
  }

  ngOnInit(): void {
    console.log('Match ID:', this.matchId);
    if (
      this.test_x !== undefined &&
      this.test_y !== undefined &&
      this.test_x !== null &&
      this.test_y !== null
    )
      this.sendCoordinates(this.test_x, this.test_y, true);

    this.langData = (data as any).default;
    this.getTopMomentData();
    this.formatRosters();
    this.parseEditData(this.editData);
    console.log('Match ID:', this.matchId);
  }

  public setTime() {
    const min = +this.min > 9 ? `${this.min}` : `0${this.min}`;
    const sec = +this.sec > 9 ? `${this.sec}` : `0${this.sec}`;
    this.time = `${min}:${sec}`;
  }

  public emitTimeChange() {
    const time = this.time.split(':');
    if (+time[0] > 19 && +time[1] > 0) {
      this.min = 19;
      this.setTime();
    }
    this.changeCasomira.emit({ time: this.time, period: this.period });
  }

  setAttackingPlayers() {}

  parseEditData(data: any) {
    if (Object.keys(data).length != 0) {
      this.editPage = true;
      if (data.supervision != null) {
        this.supervize = data.supervision;
      }
      this.goal = data.result === 'goal';
      this.time = TimeUtils.formatTime(
        TrackingUtils.getCasomira(data.time, this.overtimeLength)
      );
      if (this.time.length === 4) this.time = '0' + this.time;
      console.log('Data:', data);
      this.shotId = data.shotId;

      this.players[0].id = data.playerId;
      this.players[1].id = data.blockerId;
      this.players[2].id = data.goalkeeperId;
      if (this.goal && data.goalkeeperId === null) {
        this.goalkeeper = {
          id: '10000000000',
          name: 'Prázdná branka',
          team: '',
          active: true,
        };
        this.players[2] = this.goalkeeper;
      }

      //Sem napojit dáta o útočiacich hráčov
      if (data.attackersCount != null && data.defendersCount != null) {
        this.attacking_players = data.attackersCount;
        this.defending_players = data.defendersCount;
      }

      this.realDate_shot = data.realDate;

      setTimeout(() => {
        this.players[0].name = this.getPlayerTemplate(
          Number(this.players[0].id)
        );
        this.players[0].team = this.getPlayerTeam(Number(this.players[0].id));

        this.players[1].name = this.getPlayerTemplate(
          Number(this.players[1].id)
        );
        this.players[2].name = this.getPlayerTemplate(
          Number(this.players[2].id)
        );
      }, 200);

      if (this.players[0].id === undefined) {
        this.players[0] = null;
      }
      if (this.players[1].id === undefined) {
        this.players[1] = null;
      }
      if (this.players[2].id === undefined) {
        this.players[2] = null;
      }

      this.lokace = data.coordinates.x + ';' + data.coordinates.y;
      this.result = data.result;
      this.shot_active = data.attributes.gateZone;

      this.oneTimer = data.attributes.oneTimer;
      this.oddManRush = data.attributes.oddManRush;
      this.forecheck = data.attributes.forecheck;
      this.quickAttack = data.attributes.quickAttack;
      this.rebound = data.attributes.rebound;
      this.screeningPlayer = data.attributes.screeningPlayer;
      this.blocked = data.attributes.blocked;
      this.deflected = data.attributes.deflected;
      this.penaltyShot = data.attributes.penaltyShot;
      this.caught_save_type = data.attributes.saveType;

      if (
        this.result == 'saved' &&
        (this.caught_save_type == '' || this.caught_save_type == null)
      ) {
        this.show_saved_tooltyp = true;
      }

      if (data.attributes.attackersCount != null) {
        this.attacking_players = data.attributes.attackersCount;
      }
      if (data.attributes.defendersCount != null) {
        this.defending_players = data.attributes.defendersCount;
      }

      this.puckWonService.getPuckWon(this.matchId).subscribe(
        (data: any) => {
          this.puckWon_list = data;

          /*
          if (data[0].supervision != null) {
            this.supervize_zisk = data[0].supervision;
          } else {
            if (data.supervision != null) {
              this.supervize_zisk = data.supervision;
            }
          }
          */

          this.puckWon_list.forEach((item) => {
            if (item.shotId == this.shotId) {
              this.puckWon_exist = true;
              this.puckWonId = item.puckWonId;
              this.zoneExitId = item.zoneExitId;
              this.goalieTouch = item.goalieTouch;
              this.underPressure = item.underPressure;
              this.participation = item.participation;
              this.realDate_puckWon = data.realDate;

              this.players[3].id = item.playerId;
              setTimeout(() => {
                this.players[3].name = this.getPlayerTemplate(
                  Number(this.players[3].id)
                );
                this.players[3].team = this.getPlayerTeam(
                  Number(this.players[3].id)
                );
              }, 200);
              if (this.players[3].id === undefined) {
                this.players[3] = null;
              }

              this.puck_time2 = TimeUtils.formatTime(
                TrackingUtils.getCasomira(item.time, this.overtimeLength)
              );
              if (this.puck_time2.length == 4) {
                this.puck_time2 = '0' + this.puck_time2;
              }

              this.puck_type = item.type;
              this.lokace2 = item.coordinates.x + ';' + item.coordinates.y;

              this.players[4].id = item.gainSharePlayerId;
              setTimeout(() => {
                this.players[4].name = this.getPlayerTemplate(
                  Number(this.players[4].id)
                );
              }, 200);
              if (this.players[4].id === undefined) {
                this.players[4] = null;
              }

              this.players[5].id = item.puckLostPlayerId;
              setTimeout(() => {
                this.players[5].name = this.getPlayerTemplate(
                  Number(this.players[5])
                );
              }, 200);
              if (this.players[5] === undefined) {
                this.players[5] = null;
              }
            }
          });
        },
        (error) => {
          this.puckWon_exist = false;
        }
      );

      this.passService.getPass(this.matchId).subscribe(
        (data: any) => {
          /*
          if (data[0].supervision != null) {
            this.supervize_pass = data[0].supervision;
          } else {
            if (data.supervision != null) {
              this.supervize_pass = data.supervision;
            }
          }
          */

          this.passes_list = data;
          this.passes_list.forEach((item) => {
            if (item.shotId == this.shotId) {
              this.pass_exist = true;
              this.prihravka_na_strelu = true;
              this.passId = item.passId;
              this.completeToSlot = item.completeToSlot;
              this.nonCompleteToSlot = item.nonCompleteToSlot;
              this.blockedToSlot = item.blockedToSlot;
              this.players[6].id = item.playerId;

              setTimeout(() => {
                this.players[6].name = this.getPlayerTemplate(
                  Number(this.players[6].id)
                );
              }, 200);
              if (this.players[6].id === undefined) {
                this.players[6] = null;
              }

              this.puck_time3 = TimeUtils.formatTime(
                TrackingUtils.getCasomira(item.time, this.overtimeLength)
              );
              if (this.puck_time3.length == 4) {
                this.puck_time3 = '0' + this.puck_time3;
              }
              this.lokace3 = item.coordinates.x + ';' + item.coordinates.y;
            }
          });

          if (this.prihravka_na_strelu == undefined) {
            this.prihravka_na_strelu = false;
          }
        },
        (error) => {
          this.pass_exist = false;
          this.prihravka_na_strelu = false;
        }
      );
    }
  }

  getCoordinatesMap(lokace: string, axis: string) {
    let coordinates = [];
    coordinates = lokace.split(';');

    if (!this.reversed_sides) {
      if (axis === 'x')
        return Math.round(
          TrackingUtils.remap(coordinates[0], -100, 100, 0, 540)
        );
      return Math.round(TrackingUtils.remap(coordinates[1], -100, 100, 270, 0));
    } else {
      if (axis === 'x')
        return Math.round(
          TrackingUtils.remap(coordinates[0], -100, 100, 540, 0)
        );
      return Math.round(TrackingUtils.remap(coordinates[1], -100, 100, 0, 270));
    }
  }

  selectPlayerByClick(id: string) {
    this.players.forEach((player) => {
      if (player.active) {
        player.id = id;
        player.active = false;
      }
    });

    (document.activeElement as HTMLElement).blur();
  }

  selectAttackerCount(count: number) {
    this.attacking_players = count;
    if (this.defending_players > this.attacking_players - 1)
      this.defending_players = undefined;
  }

  selectDefendingCount(count: number) {
    this.defending_players = count;
    if (this.defending_players > this.attacking_players - 1)
      this.attacking_players = undefined;
  }

  getPlayerTeam(id: number) {
    return TrackingUtils.getPlayerTeam(id, this.roster_home, this.roster_away);
  }

  setResult(result: string) {
    this.result = result;
    this.mapComponent.disableCeInit();

    if (result === 'saved') {
      this.players[1].id = null;
      this.players[1].name = '';
      if (this.show_saved_tooltyp) this.show_saved_tooltyp = false;
      else this.show_saved_tooltyp = true;
    } else {
      this.caught_save_type = null;
      this.show_saved_tooltyp = false;
    }

    if (result === 'missed' || result === 'post') {
      this.shotChange('');
      this.mapComponent.toggle('');
      this.players[1].id = null;
      this.players[1].name = '';

      this.players[2].id = null;
      this.players[2].name = '';
    }

    if (result === 'goal') {
      this.players[1].id = null;
      this.players[1].name = '';
      this.goalkeeper = {
        id: '10000000000',
        name: 'Prázdná branka',
        team: '',
        active: true,
      };
      this.players[2] = this.goalkeeper;
      this.mapComponent.disableCe();
      this.shot_active = '';
      this.top_moment_type = 'goal';
    } else {
      if (this.top_moment_type === 'goal') this.top_moment_type = null;
    }

    if (result === 'deflected') {
      this.players[2].id = null;
      this.players[2].name = '';

      this.shotChange('');
      this.mapComponent.toggle('');
    }
  }

  getPlayerTemplate(id: number) {
    return TrackingUtils.getPlayerTemplate(
      id,
      this.roster_home,
      this.roster_away
    );
  }

  showPlayground() {
    this.show_playground = !this.show_playground;
    this.scroller.nativeElement.scrollLeft = this.show_playground
      ? this.scroller.nativeElement.scrollWidth
      : 0;
  }

  replay(time_value: string) {
    let time = this.getTimeInSeconds(time_value);

    this.reloadVideo.emit(time);
  }

  formatRosters() {
    this.roster_home.forEach((player) => {
      player.team = 'home';
      player.search = player.name + ' ' + player.surname + '' + player.jersey;

      this.roster_all.push(player);
    });
    this.roster_away.forEach((player) => {
      player.team = 'away';
      player.search = player.name + ' ' + player.surname + '' + player.jersey;

      this.roster_all.push(player);
    });

    this.roster_all.forEach((item) => {
      if (item.position === 'goalkeeper') {
        this.goalkeepers_all.push(item);
      }
    });
    this.roster_home.forEach((item) => {
      if (item.position === 'goalkeeper') {
        this.goalkeepers_home.push(item);
      }
    });
    this.roster_away.forEach((item) => {
      if (item.position === 'goalkeeper') {
        this.goalkeepers_away.push(item);
      }
    });
  }

  getCaughtSaveTypeLabel() {
    if (this.caught_save_type === 'freeze') return 'Chytil a podržel';
    if (this.caught_save_type === 'controlled') return 'Vyrazil mimo slot';
    if (this.caught_save_type === 'uncontrolled') return 'Vyrazil do slotu';
    return '';
  }

  formatPosition(position: string) {
    return TrackingUtils.formatPosition(position);
  }

  togglePuckType(type: string) {
    this.puck_type = type;

    if (type === 'no_battle') {
      this.players[4].id = null;
      this.players[4].name = '';
      this.players[5].id = null;
      this.players[5].name = '';
    }
  }

  prihravkaNaStreluToggle(val: boolean) {
    this.prihravka_na_strelu = val;

    if (!val) {
      this.players[6].id = null;
      this.puck_time3 = '';
      this.lokace3 = '';
    }
  }

  autocompleListFormatter = (data: any) => {
    let position = this.formatPosition(data.position);

    let team = this.getPlayerTeamShortcut(data.id);

    let html = `<img src="/assets/image/logos/${team}.png" onerror="this.src='/assets/image/logos/default.png';" onerror="this.src='/assets/image/logos/default.png';" width="18px" height="18px" style="vertical-align:top;margin-top:0px;margin-right:4px"><span style="color:#637680;width:29px;display:inline-block;padding-left:3px">#${data.jersey}</span>&nbsp;<span>${data.surname} ${data.name}</span><span style="float:right;border:1px solid #1c7cd6;color:#1c7cd6;text-align:center;border-radius:100%;font-size:8px;padding-top:1px;width:16px;height:16px;">${position}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  };

  autocompleValueFormatter = (data: any) => {
    let position = this.formatPosition(data.position);
    let team = this.getPlayerTeamShortcut(data.id);
    let text =
      '#' +
      data.jersey +
      ' ' +
      data.surname +
      ' ' +
      data.name +
      ' (' +
      team +
      ')';
    return text;
  };

  getPlayerTeamShortcut(id: number) {
    return TrackingUtils.getPlayerTeamShortcut(
      id,
      this.roster_home,
      this.roster_away
    );
  }

  close() {
    this.closeCanvas.emit();
  }

  saveAndNext() {
    this.saveType.emit({
      save_type: this.save_type,
      id: this.shotId,
      type: 'shot',
    });
  }

  sendToast(type: string, message: string, text: string) {
    this.toast.emit({ type, message, text });
  }

  shotChange(shot_type: any) {
    this.shot_active = shot_type;
  }

  checkXY() {
    this.lokace_active = true;
  }

  uncheckXY() {
    setTimeout(() => (this.lokace_active = false), 500);
  }

  checkXY2() {
    this.lokace_active2 = true;
  }

  uncheckXY2() {
    setTimeout(() => {
      let coordinates = [];
      coordinates = this.lokace2.split(';');

      let type = this.getPlayerTeam(Number(this.players[0].id));

      if (type == 'home') {
        if (Number(coordinates[0]) <= -29) {
          this.obranne_pasmo_strilejiciho_tymu = true;
          if (this.puck_type == 'blocked') {
            this.puck_type = '';
          }
        } else {
          this.obranne_pasmo_strilejiciho_tymu = false;
        }
      } else if (type == 'away') {
        if (Number(coordinates[0]) >= 29) {
          this.obranne_pasmo_strilejiciho_tymu = true;
          if (this.puck_type == 'blocked') {
            this.puck_type = '';
          }
        } else {
          this.obranne_pasmo_strilejiciho_tymu = false;
        }
      }
    }, 200);

    setTimeout(() => (this.lokace_active2 = false), 500);
  }

  checkXY3() {
    this.lokace_active3 = true;
  }

  uncheckXY3() {
    setTimeout(() => (this.lokace_active3 = false), 500);
  }

  sendCoordinates(x: number, y: number, external: boolean) {
    if (this.lokace_active) {
      this.lokace = x + ';' + y;
      this.lokace_active = false;
    }
    if (this.lokace_active2) {
      this.lokace2 = x + ';' + y;
      this.lokace_active2 = false;
    }
    if (this.lokace_active3) {
      this.lokace3 = x + ';' + y;
      this.lokace_active3 = false;
    }
  }

  formatTimeNumber(value: number) {
    return value < 10 ? '0' + value : value;
  }

  formatTime2(seconds: number) {
    return (
      (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds
    );
  }
  /*  TODO: delete useless
  focusTime() {
    this.time =
      this.formatTimeNumber(this.minute) +
      ':' +
      this.formatTimeNumber(this.second);
  } */

  focusPuckTime2() {
    this.puck_time2 =
      this.formatTimeNumber(this.minute) +
      ':' +
      this.formatTimeNumber(this.second);
    if (this.puck_time2 === 'undefined:undefined') this.puck_time2 = '';
  }

  focusPuckTime3() {
    this.puck_time3 =
      this.formatTimeNumber(this.minute) +
      ':' +
      this.formatTimeNumber(this.second);
  }

  onMapClick(event): void {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    this.show_coordinates = true;

    if (!this.lokace_active && !this.lokace_active2 && !this.lokace_active3)
      return;

    const test_x = TrackingUtils.recountCoordinates(
      x,
      y,
      'x',
      this.reversed_sides
    );
    const test_y = TrackingUtils.recountCoordinates(
      x,
      y,
      'y',
      this.reversed_sides
    );

    if (this.lokace_active)
      this.onCoordinatesChange.emit({ test_x: test_x, test_y: test_y });

    this.sendCoordinates(test_x, test_y, false);
  }

  selectOpponentGoalie(opponentPlayerId: string) {
    let team = '';
    let goalies = [];
    this.roster_all.forEach((player) => {
      if (player.id === opponentPlayerId) {
        team = player.team;
      }
    });

    if (team === 'home') {
      let count = 0;
      this.roster_away.forEach((player) => {
        if (player.position === 'goalkeeper') {
          goalies.push(player.id);
        }
      });
    } else if (team === 'away') {
      let count = 0;
      this.roster_home.forEach((player) => {
        if (player.position === 'goalkeeper') {
          goalies.push(player.id);
        }
      });
    }

    let goalieId;
    if (this.activeFormation.includes(goalies[0])) goalieId = goalies[0];
    if (this.activeFormation.includes(goalies[1])) goalieId = goalies[1];
    if (goalieId === undefined) goalieId = '10000000000';

    if (this.result !== 'missed' && this.result !== 'deflected') {
      this.players[2].id = goalieId;
      this.players[2].name = this.getPlayerTemplate(Number(goalieId));
    }
  }

  getOwnTeamRoster(player: string) {
    return TrackingUtils.getOwnTeamRoster(
      player,
      this.roster_home,
      this.roster_away,
      this.roster_all
    );
  }

  getOpponentRoster(player: string) {
    return TrackingUtils.getOpponentRoster(
      player,
      this.roster_home,
      this.roster_away,
      this.roster_all
    );
  }

  getOpponentGoalkeepers(player: string) {
    let type = this.getPlayerTeam(Number(player));

    let roster = [];

    if (type === 'home') {
      roster = this.goalkeepers_away;

      let found: boolean =
        roster.find((player) => player.id == 0) !== undefined;
      if (!found) {
        roster.push({
          id: '10000000000',
          name: '',
          surname: 'Prázdná branka',
          jersey: null,
          position: 'goalkeeper',
          stick: '',
          lineUp: null,
          team_shortcut: '',
          team_id: null,
          lajna: null,
          team: '',
          search: 'Prázdná branka',
        });
      }

      return roster;
    }
    if (type === 'away') {
      roster = this.goalkeepers_home;

      let found: boolean =
        roster.find((player) => player.id == 0) !== undefined;
      if (!found) {
        roster.push({
          id: '10000000000',
          name: '',
          surname: 'Prázdná branka',
          jersey: null,
          position: 'goalkeeper',
          stick: '',
          lineUp: null,
          team_shortcut: '',
          team_id: null,
          lajna: null,
          team: '',
          search: 'Prázdná branka',
        });
      }

      return roster;
    }
    roster = this.goalkeepers_all;

    let found = roster.forEach((player) => player.id == 0) !== undefined;
    if (!found) {
      roster.push({
        id: '10000000000',
        name: '',
        surname: 'Prázdná branka',
        jersey: null,
        position: 'goalkeeper',
        stick: '',
        lineUp: null,
        team_shortcut: '',
        team_id: null,
        lajna: null,
        team: '',
        search: 'Prázdná branka',
      });
    }

    return roster;
  }

  changePlayer1() {
    setTimeout(() => {
      let coordinates = [];
      coordinates = this.lokace2.split(';');

      let type = this.getPlayerTeam(Number(this.players[0].id));

      if (type == 'home') {
        if (Number(coordinates[0]) <= -29) {
          this.obranne_pasmo_strilejiciho_tymu = true;
          if (this.puck_type == 'blocked') {
            this.puck_type = '';
          }
        } else {
          this.obranne_pasmo_strilejiciho_tymu = false;
        }
      } else if (type == 'away') {
        if (Number(coordinates[0]) >= 29) {
          this.obranne_pasmo_strilejiciho_tymu = true;
          if (this.puck_type == 'blocked') {
            this.puck_type = '';
          }
        } else {
          this.obranne_pasmo_strilejiciho_tymu = false;
        }
      }
      this.player1Changed(this.players[0].id);
    }, 200);
  }

  player1Changed(newVal) {
    this.players[0].team = newVal.team;

    let player1_team = this.getPlayerTeamShortcut(Number(this.players[0].id));
    let player2_team = this.getPlayerTeamShortcut(Number(this.players[1].id));
    let player4_team = this.getPlayerTeamShortcut(Number(this.players[3].id));
    let player5_team = this.getPlayerTeamShortcut(Number(this.players[4].id));

    if (player1_team != '') {
      if (player1_team == player2_team) {
        this.sendToast(
          'error',
          'Chyba!',
          'Střílející hráč nemůže být ze stejného týmu jako blokující hráč.'
        );
        setTimeout(() => {
          this.players[0].id = null;
          this.players[0].name = '';
          this.players[0].team = '';
        }, 100);
      } else {
        this.selectOpponentGoalie(this.players[0].id);
      }

      if (player1_team != '' && player4_team != '') {
        if (player1_team != player4_team) {
          this.sendToast(
            'error',
            'Chyba!',
            'Střílející hráč musí být z opačného týmu než hráč, který získal puk.'
          );
          setTimeout(() => {
            this.players[0].id = null;
            this.players[0].name = '';
            this.players[0].team = '';
          }, 100);
        }
      }
    }
  }

  player1ChangedDetectDeleted(value) {
    if (value === undefined || value === '') {
      this.players[0].id = null;
      this.players[0].team = '';
    }
  }

  player2Changed(newVal) {
    this.players[1].id = newVal.id;

    let player2_team = this.getPlayerTeamShortcut(Number(this.players[1].id));
    let player1_team = this.getPlayerTeamShortcut(Number(this.players[0].id));

    if (player2_team !== '') {
      if (player1_team === player2_team) {
        this.sendToast(
          'error',
          'Chyba!',
          'Blokující hráč nemůže být ze stejného týmu jako střílející hráč.'
        );
        setTimeout(() => {
          this.players[1].name = '';
          this.players[0].team = '';
        }, 100);
      }
    }
  }

  player2ChangedDetectDeleted(value) {
    if (value === undefined || value === '') {
      this.players[1].id = null;
    }
  }

  player3Changed(newVal) {
    this.players[2].id = newVal.id;
  }

  player3ChangedDetectDeleted(value) {
    if (value === undefined || value === '') {
      this.players[2].id = null;
    }
  }

  player4Changed(newVal) {
    this.players[3].id = newVal.id;
    this.players[3].team = newVal.team;

    let player4_team = this.getPlayerTeamShortcut(Number(this.players[3].id));
    let player5_team = this.getPlayerTeamShortcut(Number(this.players[4].id));

    if (player4_team != '' && player5_team != '') {
      if (player4_team != player5_team) {
        this.sendToast(
          'error',
          'Chyba!',
          'Hráč, který získal puk a hráč s podílem na zisku puku musí být ze stejného týmu.'
        );
        setTimeout(() => {
          this.players[3].id = null;
          this.players[3].name = '';
          this.players[3].team = '';
        }, 100);
      }
    }
  }

  player4ChangedDetectDeleted(value) {
    if (value === undefined || value === '') {
      this.players[3].id = null;
      this.players[3].team = '';
    }
  }

  player5Changed(newVal) {
    this.players[4].id = newVal.id;
  }

  player5ChangedDetectDeleted(value) {
    if (value === undefined || value === '') this.players[4].id = null;
  }

  player6Changed(newVal) {
    this.players[5].id = newVal.id;
  }

  player6ChangedDetectDeleted(value) {
    if (value === undefined || value === '') this.players[5].id = null;
  }

  player7Changed(newVal) {
    this.players[6].id = newVal.id;
  }

  player7ChangedDetectDeleted(value) {
    if (value === undefined || value === '') this.players[6].id = null;
  }

  checkUndefinedPlayer() {
    setTimeout(() => {
      if (this.players[0].id === undefined) {
        this.players[0].name = '';
        this.players[0].team = '';
      }

      for (let i = 1; i < this.players.length; i++)
        if (this.players[i] === undefined) this.players[i].name = '';
    }, 100);
  }

  toggleOneTimer() {
    this.oneTimer = !this.oneTimer;
  }

  togglePenaltyShot() {
    this.penaltyShot = !this.penaltyShot;
  }

  toggleDeflected() {
    this.deflected = !this.deflected;
  }

  toggleScreeningPlayer() {
    this.screeningPlayer = !this.screeningPlayer;
  }

  toggleTopMomentTooltyp() {
    this.show_top_moment_tooltyp = !this.show_top_moment_tooltyp;
  }

  toggleCaughtSaveType(type) {
    if (this.caught_save_type == type) {
      this.result = '';
      this.caught_save_type = '';
      this.show_saved_tooltyp = false;
    } else {
      this.caught_save_type = type;
      this.show_saved_tooltyp = false;
    }
  }

  toggleTopMomentType(type) {
    if (this.top_moment_type == type) this.top_moment_type = null;
    else this.top_moment_type = type;
  }

  toggleOddManRush() {
    if (this.oddManRush) {
      this.oddManRush = false;
      this.show_tooltyp = false;
    } else {
      this.oddManRush = true;
      this.show_tooltyp = true;
    }
  }

  toggleForecheck() {
    this.forecheck = !this.forecheck;
  }

  stringy(data: any) {
    return JSON.stringify(data);
  }

  logError(data: any) {
    this.defaultService.error(data).subscribe((data: any) => {});
  }

  // Pokud je rozdíl mezi zadaným časem střely a časem
  // zisku puku na střelu vyšší než 31 a více vteřin,
  // tak se další pole Zisk puku na střelu zneaktivní a
  // je možno zápis uložit bez vyplnění podrobností zisku puku.
  checkTimeDiffs() {
    /*
    let ok = true;

    if (this.time == "") {
      ok = false;
    }
    let times = [];
    times = this.time.split(':');
    let time = (Number(times[0]) * 60) + Number(times[1]);
    if (this.period == 1) {
      time = (time - 1200) * -1;
    } else if (this.period == 2) {
      time = ((time - 1200) * -1) + 1200;
    } else if (this.period == 3) {
      time = ((time - 2400) * -1) + 1200;
    } else if (this.period == 4) {
      time = ((time - 3600) * -1) + this.overtimeLength;
    }
    if (isNaN(time)) {
      ok = false;
    }

    if (this.puck_time2 == "") {
      ok = false;
    }
    let times2 = [];
    times2 = this.puck_time2.split(':');
    let time2 = (Number(times2[0]) * 60) + Number(times2[1]);
    if (this.period == 1) {
      time2 = (time2 - 1200) * -1;
    } else if (this.period == 2) {
      time2 = ((time2 - 1200) * -1) + 1200;
    } else if (this.period == 3) {
      time2 = ((time2 - 2400) * -1) + 1200;
    } else if (this.period == 4) {
      time2 = ((time2 - 3600) * -1) + 1200;
    }
    if (isNaN(time2)) {
      ok = false;
    }


    if (ok) {
      if ((time - time2) > 31) {
        this.player4 = "";
        this.lokace2 = "";
        this.puck_type = "";
        this.player5 = "";
        this.player6 = "";

        return true;
      } else {
        return false
      }
    } else {
      return false
    }
    */
    return false;
  }

  toggleMenu(event, contextmenu_attribute: string) {
    this.contextmenuX = event.pageX;
    this.contextmenuY = event.pageY;
    this.contextmenu_attribute = contextmenu_attribute;
    this.contextmenu = true;
    this.showhelp = false;
    this.active_supervize = 'shot';
  }

  toggleMenuZisk(event, contextmenu_attribute: string) {
    this.contextmenuX = event.pageX;
    this.contextmenuY = event.pageY;
    this.contextmenu_attribute = contextmenu_attribute;
    this.contextmenu = true;
    this.showhelp = false;
    this.active_supervize = 'zisk';
  }

  toggleMenuPass(event, contextmenu_attribute: string) {
    this.contextmenuX = event.pageX;
    this.contextmenuY = event.pageY;
    this.contextmenu_attribute = contextmenu_attribute;
    this.contextmenu = true;
    this.showhelp = false;
    this.active_supervize = 'pass';
  }

  changeGoalie(data: any) {
    if (data.id === '10000000000') {
      this.shotChange('');
      this.mapComponent.toggle('');
    }
  }

  disableContextMenu() {
    this.contextmenu = false;
  }

  toggleHelp() {
    if (this.contextmenu_attribute === 'winnerId') {
      this.help_title = 'Vítěz';
      this.help_desc = 'Nápověda k vítěži.';
    } else if (this.contextmenu_attribute === 'loserId') {
      this.help_title = 'Poražený';
      this.help_desc = 'Nápověda k poraženému.';
    } else if (this.contextmenu_attribute === 'cleanWin') {
      this.help_title = 'Čistě vyhrané vhazování';
      this.help_desc = 'Nápověda k čistě vyhranému vhazování.';
    }
    this.showhelp = true;
  }

  toggleSupervize() {
    if (this.active_supervize == 'shot') {
      let attribute = this.contextmenu_attribute;
      this.supervize[attribute] = !this.supervize[attribute];
    } else if (this.active_supervize == 'zisk') {
      let attribute = this.contextmenu_attribute;
      this.supervize_zisk[attribute] = !this.supervize_zisk[attribute];
    } else if (this.active_supervize == 'pass') {
      let attribute = this.contextmenu_attribute;
      this.supervize_pass[attribute] = !this.supervize_pass[attribute];
    }
  }

  updateSupervizeShot(shotId: number) {
    let data = this.supervize;
    this.shotService.updateSupervize(this.matchId, shotId, data).subscribe(
      (data: any) => {
        this.loading = false;
        this.close();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  updateSupervizePuckWon(id: number) {
    /*
    let data = this.supervize_zisk;
    this.puckWonService.updateSupervize(this.matchId, id, data)
      .subscribe((data: any) => {
        //this.loading = false;
      }, (error) => {
        //this.loading = false;
      });
      */
  }

  updateSupervizePass(id: number) {
    /*
    let data = this.supervize_pass;
    this.passService.updateSupervize(this.matchId, id, data)
      .subscribe((data: any) => {
        //this.loading = false;
      }, (error) => {
        //this.loading = false;
      });
      */
  }

  submit(type: string) {
    if (this.invalidCasomira) {
      if (this.period < 4)
        this.sendToast(
          'error',
          this.langData.invalidCasomira,
          'Chybná hodnota'
        );
      else
        this.sendToast(
          'error',
          this.langData.invalidCasomiraOT +
            (this.overtimeLength > 300 ? ' 20:00' : ' 05:00'),
          'Chybná hodnota'
        );
    } else {
      if (this.validace()) {
        this.save_type = type;
        this.loading = true;
        Object.keys(this.editData).length === 0
          ? this.createShot()
          : this.updateShot();
      }
    }
  }

  validace() {
    let ok = true;

    let time1Seconds = 0;
    let time2Seconds = 0;
    let time3Seconds = 0;
    if (this.time !== '') {
      time1Seconds = this.getTimeInSeconds(this.time);
    }

    if (this.puck_time2 !== '') {
      time2Seconds = this.getTimeInSeconds(this.puck_time2);
    }

    if (this.puck_time3 !== '') {
      time3Seconds = this.getTimeInSeconds(this.puck_time3);
    }

    if (!this.prihravka_na_strelu && this.oneTimer) {
      ok = false;
      this.sendToast(
        'error',
        'Chyba!',
        'Střela z první musí mít zapsanou přihrávku na střelu!'
      );
    }

    //detekce prazdneho viteze
    if (this.players[0].id === null || this.players[0] === undefined) {
      ok = false;
      this.sendToast(
        'error',
        'Chyba!',
        'Pole střílející hráč nemůže být prázdné.'
      );
    }

    //detekce nevyplneného počtu hráčov z prečíslenia
    if (this.oddManRush) {
      if (
        this.defending_players == undefined ||
        this.attacking_players == undefined
      ) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'V přečíslení je nutné vybrat počet hráču.'
        );
      }
    }

    //detekce prazdne casomiry
    if (this.time == '') {
      ok = false;
      this.sendToast('error', 'Chyba!', 'Čas časomíry nemůže být prázdný.');
    }

    //detekce nesmyslneho casu
    let time = this.getTimeInSeconds(this.time);
    if (isNaN(time)) {
      ok = false;
      this.sendToast(
        'error',
        'Chyba!',
        'Časomíra musí být ve správném formátu. (MM:SS)'
      );
    }
    if (time < 0) {
      ok = false;
      this.sendToast(
        'error',
        'Chyba!',
        'Časomíra je zadaná chybně. Musí být v rozmezí od 20:00 do 00:00'
      );
    }

    //detekce prazdne lokace
    if (this.lokace == '') {
      ok = false;
      this.sendToast('error', 'Chyba!', 'Lokace nemůže být prázdná.');
    }

    //detekce nesmyslne lokace
    let lokace = [];
    lokace = this.lokace.split(';');
    if (lokace[0] === undefined || lokace[1] === undefined) {
      ok = false;
      this.sendToast('error', 'Chyba!', 'Lokace není vyplněna správně.');
    }

    if (
      this.result === 'saved' &&
      (this.caught_save_type === '' || this.caught_save_type === null)
    ) {
      ok = false;
      this.sendToast('error', 'Chyba!', 'Není vyplněn druh zákroku!');
    }

    //detekce prazdneho druhu strely
    if (this.result === '') {
      ok = false;
      this.sendToast('error', 'Chyba!', 'Je nutné vybrat druh střely.');
    }

    //detekce logiky druhu střely
    if (this.result === 'saved') {
      if (this.players[2].id === null) {
        ok = false;
        this.sendToast('error', 'Chyba!', 'Pole brankář nemůže být prázdné.');
      }

      if (this.shot_active === '') {
        ok = false;
        this.sendToast('error', 'Chyba!', 'Je nutné vyplnit zónu branky.');
      }
    }

    if (this.result === 'goal') {
      if (this.players[2].id === null) {
        ok = false;
        this.sendToast('error', 'Chyba!', 'Pole brankář nemůže být prázdné.');
      }

      if (this.shot_active === '' && this.players[2].id !== '10000000000') {
        ok = false;
        this.sendToast('error', 'Chyba!', 'Je nutné vyplnit zónu branky.');
      }
    }

    if (this.result == 'deflected') {
      /*
      if (this.player2 == "" || this.player2 == undefined) {
        ok = false;
        this.sendToast("error", "Chyba!", "Pole blokující hráč nemůže být prázdné.");
      }
      */
    }

    let coordinates = [];
    coordinates = this.lokace.split(';');
    let type = this.getPlayerTeam(Number(this.players[0].id));
    let strela_z_obranneho_pasma = false;

    if (type === 'home') {
      strela_z_obranneho_pasma = Number(coordinates[0]) <= -29;
    } else if (type === 'away') {
      strela_z_obranneho_pasma = Number(coordinates[0]) >= 29;
    }

    if (strela_z_obranneho_pasma) {
      if (
        confirm(
          'Opravdu byla střela vyslána z obranné poloviny týmu, jehož hráč střelu vyslal?'
        )
      ) {
      } else {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Pokud střela nebyla vyslána z obranné poloviny týmu, prosím opravte lokaci střely.'
        );
      }
    }

    if (this.checkTimeDiffs()) {
      if (
        confirm(
          'Rozdíl mezi časem zisku puku na střelu a časem střely je 31 vteřin a více?'
        )
      ) {
      } else {
        ok = false;
        this.sendToast('error', 'Chyba!', 'Opravte údaje o času zisku puku.');
      }
    }

    if (
      (this.players[3].id !== null && this.players[3].id != undefined) ||
      this.puck_time2 != '' ||
      this.lokace2 != ''
    ) {
      //detekce prazdne casomiry
      if (this.puck_time2 == '') {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Čas časomíry u zisku puku nemůže být prázdný.'
        );
      }
      let time = this.getTimeInSeconds(this.time);
      //detekce nesmyslneho casu
      let time2 = this.getTimeInSeconds(this.puck_time2);
      if (isNaN(time2)) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Časomíra u zisku puku musí být ve správném formátu. (MM:SS)'
        );
      }
      if (time2 < 0) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Časomíra je zadaná chybně. Musí být v rozmezí od 20:00 do 00:00'
        );
      }

      if (time - time2 > 5) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Zisk puku musí proběhnout do 5s před střelou!'
        );
      }

      //detekce prazdne lokace
      if (this.lokace2 == '') {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Lokace u zisku puku nemůže být prázdná.'
        );
      }

      if (this.time != '' && this.puck_time2 != '') {
        if (time1Seconds < time2Seconds) {
          ok = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Zisk puku musí předcházet střele!'
          );
        }
      }

      //detekce nesmyslne lokace
      let lokace = [];
      lokace = this.lokace2.split(';');
      if (lokace[0] == undefined || lokace[1] == undefined) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Lokace u zisku puku není vyplněna správně.'
        );
      }

      if (this.players[3].id == null || this.players[3].id === undefined) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Hráč, který získal puk nemůže být prázdné.'
        );
      }

      //detekce druhu zisku puku
      if (this.puck_type == '') {
        ok = false;
        this.sendToast('error', 'Chyba!', 'Je nutné vyplnit druh zisku puku.');
      }
    }

    if (this.prihravka_na_strelu == true) {
      if (this.players[6] === null || this.players[6].id === undefined) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Pole přihrávající hráč nemůže být prázdné.'
        );
      }

      //detekce prazdne casomiry
      if (this.puck_time3 == '') {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Čas časomíry u přihrávky nemůže být prázdný.'
        );
      }

      //detekce nesmyslneho casu
      let time = this.getTimeInSeconds(this.puck_time3);
      if (isNaN(time)) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Časomíra u přihrávky musí být ve správném formátu. (MM:SS)'
        );
      }
      if (time < 0) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Časomíra je zadaná chybně. Musí být v rozmezí od 20:00 do 00:00'
        );
      }

      if (this.puck_time2 != '' && this.puck_time3 != '') {
        if (time3Seconds < time2Seconds) {
          ok = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Zisk puku musí předcházet přihrávce!'
          );
        }
      }

      if (this.time != '' && this.puck_time3 != '') {
        if (time1Seconds < time3Seconds) {
          ok = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Přihrávka musí předcházet střele!'
          );
        }

        if (
          time1Seconds - time3Seconds > 3 ||
          time3Seconds - time1Seconds > 3
        ) {
          ok = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Přihrávka musí proběhnout do 3s před střelou!'
          );
        }
      }

      //detekce prazdne lokace
      if (this.lokace3 == '') {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Lokace u přihrávky nemůže být prázdná.'
        );
      }

      //detekce nesmyslne lokace
      let lokace = [];
      lokace = this.lokace3.split(';');
      if (lokace[0] == undefined || lokace[1] == undefined) {
        ok = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Lokace u přihrávky není vyplněna správně.'
        );
      }
    }

    if (this.players[2].id == '10000000000' && this.result !== 'goal') {
      ok = false;
      this.sendToast(
        'error',
        'Chyba!',
        'Střela na branku bez brankáře může být jedině gól.'
      );
    }

    if (this.result === 'deflected' && this.players[3].id === null) {
      this.sendToast(
        'info',
        'Info',
        'Právě uložená zblokovaná střela nemá vyplněného protihráče ztrácejícího držení puku. Pokud došlo k zisku puku mimo obranné pásmo, je nutné informace doplnit.'
      );
    }

    if (
      this.result == 'saved' &&
      (this.players[2].id === null || this.players[2].id == '10000000000')
    ) {
      ok = false;
      this.sendToast(
        'error',
        'Chyba!',
        'Chycená střela musí mít vybraného brankáře z týmu soupeře střelce.'
      );
    }

    if (this.result != 'deflected' && this.result != 'missed') {
      if (
        !this.activeFormation.includes(this.players[2].id) &&
        this.players[2].id !== null &&
        this.players[2].id != '10000000000'
      ) {
        if (
          confirm(
            'Byl vybrán brankář, který v zadaném čase není na ledě. Chcete skutečně jeho výběr potvrdit?'
          )
        ) {
        } else {
          this.sendToast('error', 'Chyba!', 'Vyberte správného brankáře.');
          ok = false;
        }
      }
    }

    return ok;
  }

  createShot() {
    let attackersCount = null;
    let defendersCount = null;

    if (this.oddManRush) {
      attackersCount = this.attacking_players;
      defendersCount = this.defending_players;
    }

    let time = this.getTimeInSeconds(this.time);

    let player1 = String(this.players[0].id);
    let player2 = String(this.players[1].id);
    let player3 = String(this.players[2].id);

    if (this.players[2].id == '10000000000') {
      player3 = null;
    }

    player1 = player1 === 'null' ? null : player1;
    player2 = player2 === 'null' ? null : player2;
    player3 = player3 === 'null' ? null : player3;

    let lokace = [];
    lokace = this.lokace.split(';');

    let forecheck = this.forecheck;
    if (forecheck == null) {
      forecheck = false;
    }

    let gateZone = this.shot_active;
    if (gateZone == '') {
      gateZone = null;
    }

    let app = 'tracking';
    if (this.page_type === 'supervize') {
      app = 'supervision';
    }

    const data = {
      time,
      playerId: player1,
      blockerId: player2,
      goalkeeperId: player3,
      result: this.result,
      realDate: new Date(),
      coordinates: {
        x: lokace[0],
        y: lokace[1],
      },
      attributes: {
        rebound: this.rebound,
        screeningPlayer: this.screeningPlayer,
        oneTimer: this.oneTimer,
        blocked: this.blocked,
        quickAttack: this.quickAttack,
        forecheck: forecheck,
        oddManRush: this.oddManRush,
        gateZone: gateZone,
        attackersCount: attackersCount,
        defendersCount: defendersCount,
        deflected: this.deflected,
        penaltyShot: this.penaltyShot,
        saveType: this.caught_save_type,
      },
      app: app,
    };

    if (
      this.players[3].id === null ||
      this.puck_time2 === '' ||
      this.lokace2 === ''
    ) {
      this.shotService.createShot(this.matchId, data).subscribe(
        (resp: any) => {
          this.emitTimeChange();
          this.updateSupervizeShot(resp.id);
          this.close();
          this.loading = false;
          let id_shot = resp.id;
          this.createTopMoment(id_shot);
          if (this.prihravka_na_strelu) {
            let puck_time3 = this.getTimeInSeconds(this.puck_time3);

            let lokace3 = [];
            lokace3 = this.lokace3.split(';');

            if (this.blockerId == '') {
              this.blockerId = null;
            }

            let app = 'tracking';
            if (this.page_type === 'supervize') {
              app = 'supervision';
            }

            let pass = {
              playerId: this.players[6].id,
              blockerId: this.blockerId,
              shotId: id_shot,
              quickAttack: this.quickAttack,
              forecheck: this.forecheck,
              oddManRush: this.oddManRush,
              completeToSlot: this.completeToSlot,
              nonCompleteToSlot: this.nonCompleteToSlot,
              blockedToSlot: this.blockedToSlot,
              coordinates: {
                x: lokace3[0],
                y: lokace3[1],
              },
              time: puck_time3,
              app: app,
            };

            this.passService.createPass(this.matchId, pass).subscribe(
              (data: any) => {
                this.updateSupervizePass(data.id);

                this.close();
                this.sendToast(
                  'success',
                  'Výborně!',
                  'Přihrávka byla vytvořena.'
                );
                this.loading = false;
              },
              (error) => {
                this.logError({
                  error: JSON.stringify(error),
                  match: this.matchId,
                  data: JSON.stringify(pass),
                });

                console.log(JSON.stringify(error));
                this.loading = false;
                this.sendToast(
                  'error',
                  'Chyba!',
                  'Během přidávání přihrávky došlo k chybě. Zkuste to znovu.'
                );
              }
            );
          } else {
            this.close();
            this.sendToast(
              'success',
              'Výborně!',
              'Vybraná událost byla vytvořena.'
            );
            this.loading = false;
          }
        },
        (error) => {
          this.logError({
            error: JSON.stringify(error),
            match: this.matchId,
            data: JSON.stringify(data),
          });
          this.loading = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Během přidávání střely došlo k chybě. Zkuste to znovu.'
          );
        }
      );
    } else {
      this.shotService.createShot(this.matchId, data).subscribe(
        (data: any) => {
          this.emitTimeChange();
          let id_shot = data.id;
          this.updateSupervizeShot(data.id);
          this.createTopMoment(id_shot);
          let puck_time2 = this.getTimeInSeconds(this.puck_time2);

          let lokace2 = [];
          lokace2 = this.lokace2.split(';');

          let player4 = this.players[3].id;
          let player5 = this.players[4].id;
          let player6 = this.players[5].id;

          if (this.goalieTouch == '') {
            this.goalieTouch = 'none';
          }

          let app = 'tracking';
          if (this.page_type === 'supervize') {
            app = 'supervision';
          }

          let puckWon = {
            time: puck_time2,
            playerId: player4,
            gainSharePlayerId: player5,
            puckLostPlayerId: player6,
            shotId: id_shot,
            zoneExitId: this.zoneExitId,
            coordinates: {
              x: lokace2[0],
              y: lokace2[1],
            },
            type: this.puck_type,
            goalieTouch: this.goalieTouch,
            underPressure: this.underPressure,
            realDate: new Date(),
            participation: this.participation,
            app: app,
          };

          this.puckWonService.createPuckWon(this.matchId, puckWon).subscribe(
            (data: any) => {
              if (this.prihravka_na_strelu) {
                let puck_time3 = this.getTimeInSeconds(this.puck_time3);

                let lokace3 = [];
                lokace3 = this.lokace3.split(';');

                if (this.blockerId == '') {
                  this.blockerId = null;
                }

                let app = 'tracking';
                if (this.page_type === 'supervize') {
                  app = 'supervision';
                }

                let pass = {
                  playerId: this.players[6].id,
                  blockerId: this.blockerId,
                  shotId: id_shot,
                  quickAttack: this.quickAttack,
                  forecheck: this.forecheck,
                  oddManRush: this.oddManRush,
                  completeToSlot: this.completeToSlot,
                  nonCompleteToSlot: this.nonCompleteToSlot,
                  blockedToSlot: this.blockedToSlot,
                  coordinates: {
                    x: lokace3[0],
                    y: lokace3[1],
                  },
                  time: puck_time3,
                  app: app,
                };

                this.updateSupervizePuckWon(data.id);

                this.passService.createPass(this.matchId, pass).subscribe(
                  (data: any) => {
                    this.updateSupervizePass(data.id);

                    this.close();
                    this.sendToast(
                      'success',
                      'Výborně!',
                      'Vybraná událost byla vytvořena.'
                    );
                    this.loading = false;
                  },
                  (error) => {
                    this.logError({
                      error: JSON.stringify(error),
                      match: this.matchId,
                      data: JSON.stringify(pass),
                    });

                    this.loading = false;
                    this.sendToast(
                      'error',
                      'Chyba!',
                      'Během přidávání přihrávky došlo k chybě. Zkuste to znovu.'
                    );
                  }
                );
              } else {
                this.close();
                this.sendToast(
                  'success',
                  'Výborně!',
                  'Vybraná událost byla vytvořena.'
                );
                this.loading = false;
              }
            },
            (error) => {
              this.logError({
                error: JSON.stringify(error),
                match: this.matchId,
                data: JSON.stringify(puckWon),
              });

              console.log(JSON.stringify(error));
              this.loading = false;
              this.sendToast(
                'error',
                'Chyba!',
                'Během přidávání zisku puku došlo k chybě. Zkuste to znovu.'
              );
            }
          );
        },
        (error) => {
          this.logError({
            error: JSON.stringify(error),
            match: this.matchId,
            data: JSON.stringify(data),
          });

          console.log(JSON.stringify(error));
          this.loading = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Během přidávání střely došlo k chybě. Zkuste to znovu.'
          );
        }
      );
    }
  }

  getTimeInSeconds(timeString: string) {
    let times = timeString.split(':');
    let time = Number(times[0]) * 60 + Number(times[1]);
    if (this.period === 1) return (time - 1200) * -1;
    if (this.period === 2) return (time - 1200) * -1 + 1200;
    if (this.period === 3) return (time - 2400) * -1 + 1200;
    for (let i: number = 3; i < 8; i++)
      if (this.period === i + 1)
        return (time - i * 1200) * -1 + this.overtimeLength;

    return time;
  }

  createTopMoment(shotId) {
    if (this.editData?.topMoments === null && this.top_moment_type === null)
      return;

    let players = [];
    if (this.players[0].id !== null) {
      players.push(this.players[0].id);
    }
    if (this.players[1].id !== null) {
      players.push(this.players[1].id);
    }
    if (this.players[2].id !== null) {
      players.push(this.players[2].id);
    }
    if (this.players[6].id !== null) {
      players.push(this.players[6].id);
    }

    players = [...new Set(players)];

    let data = {
      shotId: shotId,
      functionaryId: null,
      videoTime: null,
      time: this.getTimeInSeconds(this.time),
      type: this.result == 'goal' ? 'goal' : this.top_moment_type,
      players: players,
      app: 'tracking',
    };

    if (
      (this.top_moment_type === null || this.top_moment_type === '') &&
      this.editData?.topMoment !== undefined &&
      this.editData?.topMoments[0]?.type !== undefined &&
      this.editData?.topMoments[0]?.type !== ''
    ) {
      this.shotService
        .deleteTopMoment(
          this.matchId,
          this.editData?.topMoments[0]?.topMomentId
        )
        .subscribe(
          (data: any) => {
            console.log('Moment Deleted', data);
            this.loading = false;
          },
          (error) => console.error('Error Moment Deleted:', error)
        );
    } else if (
      (Object.keys(this.editData).length === 0 ||
        this.editData.topMoments.length === 0) &&
      this.top_moment_type !== undefined &&
      this.top_moment_type !== ''
    ) {
      this.shotService.createTopMoment(this.matchId, data).subscribe(
        (data: any) => {
          console.log('Moment Created', data);
          this.loading = false;
        },
        (error) => {
          console.log('Error Moment Created');
        }
      );
    } else {
      this.shotService
        .updateTopMoment(
          this.matchId,
          data,
          this.editData?.topMoments[0]?.topMomentId
        )
        .subscribe(
          (data: any) => {
            console.log('Moment Updated', data);
            this.loading = false;
          },
          (error) => {
            console.log('Error Moment Updated');
          }
        );
    }
  }

  getTopMomentData() {
    if (Object.keys(this.editData).length === 0) return;

    this.shotService.getTopMoment(this.matchId).subscribe(
      (data: any) => {
        this.editData.topMoments = data.filter((x) => x.shotId == this.shotId);
        this.top_moment_type = this.editData?.topMoments[0]?.type;
        if (this.top_moment_type === null && this.result === 'goal')
          this.top_moment_type = 'goal';
      },
      (error) => console.error('Error Moment get:', error)
    );
  }

  updateShot() {
    let data = this.prepareUpdateShotData();

    this.shotService.updateShot(this.matchId, this.shotId, data).subscribe(
      (data: any) => {
        this.updateSupervizeShot(this.shotId);
        this.saveAndNext();
        this.createTopMoment(this.shotId);
        //alert(this.puckWon_exist);

        //vyhra puku existuje upravim ji
        if (this.puckWon_exist) {
          this.puckWon();
        } else {
          //vyhra puku neexituje vytvorim ji
          this.puckNotWon();
        }

        if (this.prihravka_na_strelu) {
          //prihravka existuje upravim ji
          this.passExists();
        } else {
          this.passNotExists();
        }
      },
      (error) => {
        this.logError({
          error: JSON.stringify(error),
          match: this.matchId,
          data: JSON.stringify(data),
        });

        console.error('ShotServiceError:', JSON.stringify(error));
        this.loading = false;
        this.sendToast(
          'error',
          'Chyba!',
          'Během úpravy střely došlo k chybě. Zkuste to znovu.'
        );
      }
    );
  }

  puckWon(): void {
    let puck_time2 = this.getTimeInSeconds(this.puck_time2);

    let lokace2 = [];
    lokace2 = this.lokace2.split(';');

    let player4 = this.players[3].id;

    let player5 = this.players[4].id;

    let player6 = this.players[5].id;

    if (this.goalieTouch == '') {
      this.goalieTouch = 'none';
    }

    let app = 'tracking';
    if (this.page_type === 'supervize') {
      app = 'supervision';
    }

    let puckWon = {
      time: puck_time2,
      playerId: player4,
      gainSharePlayerId: player5,
      puckLostPlayerId: player6,
      shotId: this.shotId,
      zoneExitId: this.zoneExitId,
      coordinates: {
        x: lokace2[0],
        y: lokace2[1],
      },
      type: this.puck_type,
      goalieTouch: this.goalieTouch,
      underPressure: this.underPressure,
      realDate: this.realDate_puckWon,
      participation: this.participation,
      app: app,
    };

    this.sendToast('success', 'Výborně!', 'Vybraná událost byla upravena.');

    if (
      this.players[3].id === null ||
      this.puck_time2 == '' ||
      this.lokace2 == ''
    ) {
      this.puckWonService.removePuckWon(this.matchId, this.puckWonId).subscribe(
        (data: any) => {
          this.close();
          this.sendToast('success', 'Výborně!', 'Zisk puku byl smazán.');
        },
        (error) => {
          this.logError({
            error: JSON.stringify(error),
            match: this.matchId,
            data: 'remove puckwon error',
          });

          console.error('PuckWonError:', JSON.stringify(error));
          this.loading = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Během mazání zisku puku došlo k chybě. Zkuste to znovu.'
          );
        }
      );
    } else {
      this.puckWonService
        .updatePuckWon(this.matchId, this.puckWonId, puckWon)
        .subscribe(
          (data: any) => {
            this.updateSupervizePuckWon(this.puckWonId);

            this.close();
            this.sendToast('success', 'Výborně!', 'Zisk puku byl upraven.');
          },
          (error) => {
            this.logError({
              error: JSON.stringify(error),
              match: this.matchId,
              data: JSON.stringify(puckWon),
            });

            console.log(JSON.stringify(error));
            this.loading = false;
            this.sendToast(
              'error',
              'Chyba!',
              'Během úpravy zisku puku došlo k chybě. Zkuste to znovu.'
            );
          }
        );
    }
  }

  puckNotWon(): void {
    let puck_time2 = this.getTimeInSeconds(this.puck_time2);

    let lokace2 = [];
    lokace2 = this.lokace2.split(';');

    let player4 = this.players[3].id;

    let player5 = this.players[4].id;

    let player6 = this.players[5].id;

    if (this.goalieTouch == '') {
      this.goalieTouch = 'none';
    }

    let app = 'tracking';
    if (this.page_type === 'supervize') {
      app = 'supervision';
    }

    let puckWon = {
      time: puck_time2,
      playerId: player4,
      gainSharePlayerId: player5,
      puckLostPlayerId: player6,
      shotId: this.shotId,
      zoneExitId: this.zoneExitId,
      coordinates: {
        x: lokace2[0],
        y: lokace2[1],
      },
      type: this.puck_type,
      goalieTouch: this.goalieTouch,
      underPressure: this.underPressure,
      realDate: new Date(),
      participation: this.participation,
      app: app,
    };

    this.puckWonService.createPuckWon(this.matchId, puckWon).subscribe(
      (data: any) => {
        this.updateSupervizePuckWon(data.id);

        //this.close();
        this.sendToast('success', 'Výborně!', 'Zisk puku byl vytvořen.');
      },
      (error) => {
        this.logError({
          error: JSON.stringify(error),
          match: this.matchId,
          data: JSON.stringify(puckWon),
        });

        console.log(JSON.stringify(error));
        this.loading = false;
        //this.sendToast("error", "Chyba!", "Během přidávání zisku puku došlo k chybě. Zkuste to znovu.");
      }
    );
  }

  passExists(): void {
    if (this.pass_exist) {
      let puck_time3 = this.getTimeInSeconds(this.puck_time3);

      let lokace3 = [];
      lokace3 = this.lokace3.split(';');

      if (this.blockerId == '') {
        this.blockerId = null;
      }

      let app = 'tracking';
      if (this.page_type === 'supervize') {
        app = 'supervision';
      }

      let pass = {
        playerId: this.players[6].id,
        blockerId: this.blockerId,
        shotId: this.shotId,
        quickAttack: this.quickAttack,
        forecheck: this.forecheck,
        oddManRush: this.oddManRush,
        completeToSlot: this.completeToSlot,
        nonCompleteToSlot: this.nonCompleteToSlot,
        blockedToSlot: this.blockedToSlot,
        coordinates: {
          x: lokace3[0],
          y: lokace3[1],
        },
        time: puck_time3,
        app: app,
      };

      this.passService.updatePass(this.matchId, this.passId, pass).subscribe(
        (data: any) => {
          this.updateSupervizePass(this.passId);

          this.close();
          this.sendToast('success', 'Výborně!', 'Přihrávka byla upravena.');
          this.loading = false;
        },
        (error) => {
          this.logError({
            error: JSON.stringify(error),
            match: this.matchId,
            data: JSON.stringify(pass),
          });

          console.log(JSON.stringify(error));
          this.loading = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Během úpravy přihrávky došlo k chybě. Zkuste to znovu.'
          );
        }
      );
    } else {
      let puck_time3 = this.getTimeInSeconds(this.puck_time3);

      let lokace3 = [];
      lokace3 = this.lokace3.split(';');

      if (this.blockerId == '') {
        this.blockerId = null;
      }

      let app = 'tracking';
      if (this.page_type === 'supervize') {
        app = 'supervision';
      }

      let pass = {
        playerId: this.players[6].id,
        blockerId: this.blockerId,
        shotId: this.shotId,
        quickAttack: this.quickAttack,
        forecheck: this.forecheck,
        oddManRush: this.oddManRush,
        completeToSlot: this.completeToSlot,
        nonCompleteToSlot: this.nonCompleteToSlot,
        blockedToSlot: this.blockedToSlot,
        coordinates: {
          x: lokace3[0],
          y: lokace3[1],
        },
        time: puck_time3,
        app: app,
      };

      this.passService.createPass(this.matchId, pass).subscribe(
        (data: any) => {
          this.updateSupervizePass(data.id);

          this.close();
          this.sendToast('success', 'Výborně!', 'Přihrávka byla vytvořena.');
          this.loading = false;
        },
        (error) => {
          this.logError({
            error: JSON.stringify(error),
            match: this.matchId,
            data: JSON.stringify(pass),
          });

          console.log(JSON.stringify(error));
          this.loading = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Během přidávání přihrávky došlo k chybě. Zkuste to znovu.'
          );
        }
      );
    }
  }

  passNotExists(): void {
    if (this.pass_exist) {
      this.passService.removePass(this.matchId, this.passId).subscribe(
        (data: any) => {
          this.close();
          this.sendToast(
            'success',
            'Výborně!',
            'Přihrávka na střelu byla smazána'
          );
        },
        (error) => {
          this.logError({
            error: JSON.stringify(error),
            match: this.matchId,
            data: 'remove pass error',
          });

          console.log(JSON.stringify(error));
          this.loading = false;
          this.sendToast(
            'error',
            'Chyba!',
            'Během mazání přihrávky na střelu došlo k chybě. Zkuste to znovu.'
          );
        }
      );
    } else {
      this.close();
    }
  }

  prepareUpdateShotData(): object {
    let attackersCount = null;
    let defendersCount = null;

    if (this.oddManRush) {
      attackersCount = this.attacking_players;
      defendersCount = this.defending_players;
    }
    let time = this.getTimeInSeconds(this.time);

    let player1 = this.players[0].id;

    let player2 = this.players[1].id;

    let player3 = this.players[2].id;
    if (this.players[2].id == '10000000000') {
      player3 = null;
    }
    player1 = player1 === 'null' ? null : player1;
    player2 = player2 === 'null' ? null : player2;
    player3 = player3 === 'null' ? null : player3;

    let lokace = [];
    lokace = this.lokace.split(';');

    let forecheck = this.forecheck;
    if (forecheck == null) {
      forecheck = false;
    }

    let gateZone = this.shot_active;
    if (gateZone == '') {
      gateZone = null;
    }

    let app = 'tracking';
    if (this.page_type === 'supervize') {
      app = 'supervision';
    }

    return {
      time: time,
      playerId: player1,
      blockerId: player2,
      goalkeeperId: player3,
      result: this.result,
      realDate: this.realDate_shot,
      coordinates: {
        x: lokace[0],
        y: lokace[1],
      },
      attributes: {
        rebound: this.rebound,
        screeningPlayer: this.screeningPlayer,
        oneTimer: this.oneTimer,
        blocked: this.blocked,
        quickAttack: this.quickAttack,
        forecheck: forecheck,
        oddManRush: this.oddManRush,
        gateZone: gateZone,
        attackersCount: attackersCount,
        defendersCount: defendersCount,
        deflected: this.deflected,
        penaltyShot: this.penaltyShot,
        saveType: this.caught_save_type,
      },
      app: app,
    };
  }
}
