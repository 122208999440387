import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { ResizableModule } from 'angular-resizable-element';
import { DragulaModule } from 'ng2-dragula';
import { NgxMaskModule } from 'ngx-mask';
import { DpDatePickerModule } from 'ng2-date-picker';
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from 'ng-pick-datetime';
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  CheckboxComponent,
  ClipNodeComponent,
  DumpInComponent,
  DumpOutComponent,
  EventflowComponent,
  FaceOffComponent,
  HitComponent,
  LoadingComponent,
  LoadingSmallComponent,
  MapComponent,
  MatchDetailHeaderComponent,
  MatchInfoComponent,
  NavComponent,
  OffensiveZoneLossComponent,
  PenaltyComponent,
  PenaltyShootoutComponent,
  SelectSideDialogComponent,
  ShootoutComponent,
  ShotComponent,
  TeamCellComponent,
  TimeLineEventEditComponent,
  ToggleButtonComponent,
  TopMomentComponent,
  TrackingBoxComponent,
  TrackingNavComponent,
  TrackingSelectsComponent,
  VideoPlayerComponent,
  ZoneEntryComponent,
  ZoneExitComponent,
} from './components';
import { TimeMaskDirective } from './directives/time-mask.directive';
import {
  EditOverviewComponent,
  ErrorOverviewComponent,
  HokejPlayerErrorsComponent,
  LoginComponent,
  SelectComponent,
  TimelineComponent,
  TrackingComponent,
} from './pages';
import {
  ErrorWarningTranslatorPipe,
  EventTransformPipe,
  EventTranslatePipe,
  HokejPlayerErrorsSectionTranslatePipe,
  KeysPipe,
  SafePipe,
  TranslateSecondsPipe,
  TypeTranslatePipe,
} from './pipes';

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD.MM.YYYY HH:mm:ss',
  parseInput: 'DD.MM.YYYY HH:mm:ss',
  datePickerInput: 'DD.MM.YYYY HH:mm:ss',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TimelineComponent,
    NavComponent,
    TrackingComponent,
    ShotComponent,
    ZoneEntryComponent,
    HitComponent,
    DumpInComponent,
    ShootoutComponent,
    FaceOffComponent,
    DumpOutComponent,
    PenaltyComponent,
    ZoneExitComponent,
    OffensiveZoneLossComponent,
    MapComponent,
    SelectComponent,
    LoadingComponent,
    LoadingSmallComponent,
    SafePipe,
    TopMomentComponent,
    PenaltyShootoutComponent,
    TimeMaskDirective,
    TimeLineEventEditComponent,
    SelectSideDialogComponent,
    EditOverviewComponent,
    TypeTranslatePipe,
    EventTranslatePipe,
    EventTransformPipe,
    KeysPipe,
    TranslateSecondsPipe,
    TimeLineEventEditComponent,
    MatchDetailHeaderComponent,
    ToggleButtonComponent,
    ErrorOverviewComponent,
    CheckboxComponent,
    ErrorWarningTranslatorPipe,
    HokejPlayerErrorsComponent,
    ClipNodeComponent,
    TeamCellComponent,
    HokejPlayerErrorsSectionTranslatePipe,
    TrackingNavComponent,
    TrackingSelectsComponent,
    VideoPlayerComponent,
    TrackingBoxComponent,
    MatchInfoComponent,
    EventflowComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ResizableModule,
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    DpDatePickerModule,
    NguiAutoCompleteModule,
    NgxMaskModule.forRoot(),
    DragulaModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    DatePipe,
    TranslateSecondsPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Nebyl nalezen žádný hráč.';
  }
}
