import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { getApiUrl } from '../utils';

@Injectable()
export class WrongClipService {
  token: string;
  api_url: string = getApiUrl();

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getWrongClips(matchId?: number, offset?: number, limit?: number) {
    let header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };
    let array: { [key: string]: number } = {
      matchId: matchId,
      offset: offset,
      limit: limit,
      cache: Date.now(),
    };
    let url = this.addParametersToApiUrl(array);

    return this.http
      .get(this.api_url + '/api/wrongClip' + url, header)
      .pipe(map((res) => res));
  }

  resolveWrongClip(wrongClipId: string) {
    let header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http.post(
      this.api_url +
        '/api/wrongClip/' +
        wrongClipId +
        '/resolve' +
        '?cache=' +
        Date.now(),
      null,
      header
    );
  }

  removeWrongClip(wrongClipId: string) {
    let header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http.delete(
      this.api_url + '/api/wrongClip/' + wrongClipId + '?cache=' + Date.now(),
      header
    );
  }

  updateSupervize(matchId: number, id: number, data: any) {
    let header = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.token}`),
    };

    return this.http
      .put(
        this.api_url +
          '/api/supervision/zoneEntry/' +
          matchId +
          '/' +
          id +
          '?cache=' +
          Date.now(),
        data,
        header
      )
      .pipe(map((res) => res));
  }

  /**
   * Builds the rest of the url for the API call.
   * @param array Parameters containing the name and the value of each parameter.
   * @returns builded url.
   */
  addParametersToApiUrl(array: { [key: string]: any }): string {
    let added: boolean = false;
    let url: string = '';
    Object.keys(array).forEach((key) => {
      if (array[key] === undefined || array[key] === null) return;
      if (!added) {
        url += '?' + key + '=' + array[key];
        added = true;
        return;
      }
      if (added) url += '&' + key + '=' + array[key];
    });
    return url;
  }
}
