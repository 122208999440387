import { Component, Input } from '@angular/core';

@Component({
  selector: 'team-cell',
  templateUrl: './team-cell.component.html',
  styleUrls: ['./team-cell.component.scss'],
})
export class TeamCellComponent {
  @Input() homeShortcut: string;
  @Input() awayShortcut: string;
}
