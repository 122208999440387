<div class="team-cell">
  <div class="team-cell__team-left">
    <img
      src="/assets/image/logos/{{ homeShortcut }}.png"
      onerror="this.src='/assets/image/logos/default.png';"
      alt="Domácí"
    />
  </div>
  <div class="team-cell__team-names">{{ homeShortcut }}-{{ awayShortcut }}</div>
  <div class="team-cell__team-right">
    <img
      src="/assets/image/logos/{{ awayShortcut }}.png"
      onerror="this.src='/assets/image/logos/default.png';"
      alt="Hosté"
    />
  </div>
</div>
