import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { IMatch, ILog } from './../../interfaces';
import { TranslateSecondsPipe } from './../../pipes';
import { DefaultService } from '../../services';

@Component({
  selector: 'app-edit-overview',
  templateUrl: './edit-overview.component.html',
  styleUrls: ['./edit-overview.component.scss'],
  providers: [DefaultService],
})
export class EditOverviewComponent implements OnInit {
  constructor(
    public defaultService: DefaultService,
    private datePipe: DatePipe,
    private translateSecondsPipe: TranslateSecondsPipe
  ) {}

  leagues: string[];
  matches: IMatch[];
  logs: ILog[];
  matchesToDisplay: IMatch[];
  logDisplayArray: ILog[];
  loading: boolean = true;
  modal: boolean = false;
  selectedMatch: IMatch;

  time: string;
  editArray: string[];
  userArray: string[];
  eventArray: string[];

  selectedLeague: string = '-1';
  selectedEdit: string = 'all';
  selectedUser: string = 'all';
  selectedEvent: string = 'all';

  ngOnInit(): void {
    this.loadMatches();
  }

  /**
   * Loads the matches.
   */
  loadMatches(): void {
    this.defaultService.getMatches().subscribe(
      (data: IMatch[]) => {
        this.matches = data;
        this.loadLeagues();
      },
      (error) => {
        console.error('Error:', error);
        this.loading = false;
      }
    );
  }

  /**
   * Toggles collapsing of editing node and changes plus into minus and backwards.
   * @param id Index of selected edit node.
   */
  collapseEdit(id: number) {
    let node = document.getElementsByClassName('edit-node')[id + 1];
    let collapsable = node.getElementsByClassName(
      'collapsable'
    )[0] as HTMLElement;
    if (collapsable != undefined)
      if (collapsable.classList.contains('collapse')) {
        collapsable.classList.remove('collapse');
        collapsable.style.height = '0px';
      } else {
        collapsable.classList.add('collapse');
        collapsable.style.height = collapsable.scrollHeight + 'px';
      }
  }

  /**
   * Loads information of the match and its editing log.
   * @param matchId ID number of the selected match.
   */
  loadEditing(matchId: number): void {
    if (this.selectedMatch?.id === matchId) return;

    this.loading = true;
    this.clearSettings();
    this.selectedMatch = this.matches.find((match) => match.id === matchId);
    this.loadLogs(matchId);
  }

  /**
   * Clears all the settings from previously selected match.
   */
  clearSettings(): void {
    this.editArray = [];
    this.userArray = [];
    this.eventArray = [];
    this.selectedEdit = 'all';
    this.selectedUser = 'all';
    this.selectedEvent = 'all';
    this.time = null;
  }

  /**
   * Loads the logs of the matchs.
   * @param matchId ID number of the selected match.
   */
  loadLogs(matchId: number): void {
    this.logs = [];
    this.defaultService.getMatchLog(matchId).subscribe(
      (data: ILog[]) => {
        this.logs = data;
        // Filtration of the update type, where the update type has no attribute
        this.logs = this.logs.filter(
          (log) =>
            (this.hasSnapshot(log) && log.type == 'update') ||
            log.type != 'update'
        );
        this.logDisplayArray = this.logs;
        this.editArray = this.setEdittingArray(this.editArray, 'type');
        this.userArray = this.setEdittingArray(this.editArray, 'identityName');
        this.eventArray = this.setEdittingArray(this.editArray, 'entity');
      },
      (error) => console.error('Error:', error),
      () => (this.loading = false)
    );
  }

  /**
   * Filter through the log array and sets a new array of all edit names for ngModel of the select.
   * @param arr Array that will contain strings of the given type.
   * @param type Variable of the iLog type.
   * @returns Array of all strings of the given type.
   */
  setEdittingArray(arr: string[], type: string): string[] {
    arr = [];
    this.logs.forEach((log) => {
      if (!arr.includes(log[type])) arr.push(log[type]);
    });
    return arr;
  }

  /**
   * Has the log attributes (snapshot)?
   * @param log Log represented by iLog
   * @returns
   */
  hasSnapshot = (log: ILog): boolean => Object.keys(log.snapshot).length > 0;

  /**
   * Parses the attribute type.
   * @param attribute Attribute of the log.
   * @param type New | Old
   * @returns Parsed attribute type.
   */
  getAttribute(attribute, type: string): string {
    if (attribute[type] == null || attribute[type].length < 1) return '-';
    if (attribute.name == 'coordinates') {
      if (type == 'old')
        return 'x: ' + attribute[type].x + ', y: ' + attribute[type].y;
      let c: number =
        Math.round(
          Math.sqrt(
            Math.pow(attribute['old'].x * 0.3 - attribute['new'].x * 0.3, 2) +
              Math.pow(attribute['old'].y * 0.15 - attribute['new'].y * 0.15, 2)
          ) * 10
        ) / 10;
      return (
        'x: ' +
        attribute[type].x +
        ', y: ' +
        attribute[type].y +
        ' (změna o ' +
        c +
        'm)'
      );
    } else if (attribute.name == 'timestamp')
      return this.datePipe.transform(attribute[type], 'dd. MM. yyyy, hh:mm:ss');
    else if (attribute.name == 'cleanWin' || attribute.name == 'oneTimer')
      return attribute[type] === true ? 'Ano' : 'Ne';
    return attribute[type];
  }

  /**
   * Filters logs with the settings of selects.
   */
  filterLogs(): void {
    this.logDisplayArray = this.logs;
    if (this.selectedEdit != 'all')
      this.logDisplayArray = this.logDisplayArray.filter(
        (log) => log.type == this.selectedEdit
      );
    if (this.selectedUser != 'all')
      this.logDisplayArray = this.logDisplayArray.filter(
        (log) => log.identityName == this.selectedUser
      );
    if (this.selectedEvent != 'all')
      this.logDisplayArray = this.logDisplayArray.filter(
        (log) => log.entity == this.selectedEvent
      );
    if (this.time != '' && this.time != null)
      this.logDisplayArray = this.logDisplayArray.filter((log) =>
        this.translateSecondsPipe.transform(log.gameTime).includes(this.time)
      );
  }

  /**
   * Filters logs for a given time.
   */
  timeChanged(time: string): void {
    this.time = time;
    this.filterLogs();
  }

  /**
   * Filters logs for a given user array.
   */
  userChanged(userArray: string): void {
    this.selectedUser = userArray;
    this.filterLogs();
  }

  /**
   * Filters logs for a given event array.
   */
  eventChanged(eventArray: string): void {
    this.selectedEvent = eventArray;
    this.filterLogs();
  }

  /**
   * Filters logs for a given edit array.
   */
  editChanged(editArray: string): void {
    this.selectedEdit = editArray;
    this.filterLogs();
  }

  /**
   * Loads leagues that contains extraliga in it's name and places it select.
   */
  loadLeagues() {
    this.leagues = [];
    this.matches.forEach((match) => {
      if (
        match.competition.league.toLowerCase().includes('tipsport extralig') &&
        !this.leagues.includes(match.competition.league)
      )
        this.leagues.push(match.competition.league);
    });
    this.loading = false;
  }

  /**
   * Filters leagues with the settings of select.
   */
  filterLeagues(): void {
    if (this.selectedLeague === '-1') return;
    this.loading = true;
    this.matchesToDisplay = this.matches.filter(
      (match) => this.selectedLeague == match.competition.league
    );
    this.loading = false;
  }
}
